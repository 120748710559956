import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedExpense } from '../../redux/slice/myExpenseSlice';

// handlerClickedOnExpand
// onClick={handlerClickedOnExpand}
const MyExpenseList = ({
  id = '332312',
  item,
  isMyExpense = false,
}) => {
  const [expenseDataShow, setExpenseDataShow] = useState(null);
  const [isEpanded, setExpanded] = useState(false);

  // console.log('item', item);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  // const [showExpenseUpdate,setExpenseUpdate] = useState(false)
  const {
    // expenseDetailId,
    fromCity,
    toCity,
    total,
    status,
    activity,
    activityDate,
    category,
    modeOfTransport,
    fare = 0,
    categoryName = 'Meeting',
  } = item;

  const handlerSetData = (data) => {
    // setExpenseUpdate(!showExpenseUpdate)
    // console.log('data Farhan Expense clicked ????????????????????');
    if (data) {
      const {activityDateFormat} = data;
      console.log('data', data);
      // console.log('data Farhan Expense clicked ?????????????????????');
      dispatch(setSelectedExpense(data));
      navigate('/my-expense-details');
    }

    //set selected data to redux
    // navigate using naviat method to expense details
    //my expense update do api call base on that data
  };

  const handlerClicked = (data) => {
    // handlerClickedOnExpand && handlerClickedOnExpand()
    isMyExpense && handlerSetData(data);
    if (!isMyExpense) {
      // console.log('clicked on expand');
      setExpanded(!isEpanded);
      setExpenseDataShow(data);
    }
  };

  // const dividerClass =
  //   'divider-y h-[1px] w-full bg-gray-300 mt-2 overflow-y-hidden';
  const dividerClass= 'divider-y h-[2px] w-full bg-gray-300 mt-2'


  return (
    <div
      className="w-full  bg-white rounded-md p-4"
      key={id}
      onClick={() => handlerClicked(item)}
    >
      <div className="w-full flex justify-between align-items-center">
        <div className="p-1">
          <p>
            <FontAwesomeIcon icon="fa-solid fa-car-side" color="#A04D95" />{' '}
            <span style={{ color: '#79747E' }}>{modeOfTransport}</span>{' '}
            <span style={{ color: '#79747E' }}>{category}</span>
          </p>
        </div>
        <div className="">
          <p style={{ color: '#A04D95' }} className="text-">
            {status || 'Unsubmitted'}
          </p>
        </div>
      </div>
      <div className="w-full h-10 flex ">
        <div className="w-full flex justify-between">
          <div className="flex">
            <h1 className="text-xl">{fromCity}</h1>
            <div className="pl-4 pr-4 pt-1">
              <FontAwesomeIcon
                icon="fa-solid fa-right-left"
                size="xl"
                style={{ color: '#A04D95' }}
              />
            </div>
            <h1 className="text-xl">{toCity}</h1>
          </div>
          <div>
            <p>INR {total || fare}</p>
          </div>
        </div>
      </div>
      <div className="w-full h-6 flex justify-between">
        <div>
          <p style={{ color: '#79747E' }}>{activity || categoryName}</p>
        </div>
        <div>
          <p style={{ color: '#2FC23D' }} className="text-sm">
            {activityDate}
          </p>
        </div>
      </div>
      {isEpanded && <div className={dividerClass}></div>}
      {/* Expand Data Section */}
      {isEpanded && expenseDataShow && (
        <div>
          <div className="flex flex-row w-full justify-between mt-2">
            <p>Category</p>
            <p>{expenseDataShow.category || 'N/A'}</p>
          </div>
          <div className={dividerClass}></div>
          <div className="flex justify-between flex-col mt-2">
            <div className="flex flex-row w-full justify-between">
              <p>Travel Mode</p>
              <p>{expenseDataShow.travelMode}</p>
            </div>
            <div className="flex flex-row w-full justify-between">
              <p>Distance</p>
              <p>{expenseDataShow.distance}</p>
            </div>
          </div>
          <div className={dividerClass}></div>
          <div className="flex justify-between flex-col mt-2">
            <div className="flex flex-row w-full justify-between">
              <h3 className="font-bold">Fare</h3>
              <p>
                {' '}
                INR <span className="font-bold">{expenseDataShow.fare}</span>
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MyExpenseList;
