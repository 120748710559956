import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

const baseURL = 'https://keaexpenseapi.kea-sfe.com/api/ExpenseIdCreation';
// const baseURL = "http://192.168.1.243:1105/api/ExpenseIdCreation";
//

export const fetchSfcTravelingExpences = createAsyncThunk(
  'fetchSfcTravelingExpences',
  async () => {
    return axios.get(`${baseURL}/SFCBindGrid`, {
      params: { EmployeeId: 9408 },
    });
  }
);

export const sfcTravelingExpences = createSlice({
  name: 'sfcTravelingExpences',
  initialState: {
    isLoading: false,
    data: null,
    apiError: '',
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSfcTravelingExpences.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(fetchSfcTravelingExpences.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload.data;
    });
    builder.addCase(fetchSfcTravelingExpences.rejected, (state) => {
      state.apiError = 'Something went wrong';
      state.isLoading = false;
      state.data = null;
    });
  },
});

export default sfcTravelingExpences.reducer;
