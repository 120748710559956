import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

const baseURL = 'https://keaexpenseapi.kea-sfe.com/api/ExpenseIdCreation';



export const sfcUserDetails = createAsyncThunk(
  'sfcUserDetails',
  async () => {
    return axios.get(`${baseURL}/SFCEmployeeDetails`, {
      params: {employeeId: 9408},
    });
  }
);

export const sfcUserBasicInfo = createSlice({
  name: 'sfcUserBasicInfo',
  initialState: {
    isLoading: false,
    data: null,
    areaRange: null,
    apiError: '',
  },
  extraReducers: (builder) => {
    builder.addCase(sfcUserDetails.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(sfcUserDetails.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload.data;
      state.areaRange = action.payload.data.areaRange;
    });
    builder.addCase(sfcUserDetails.rejected, (state) => {
      state.apiError = 'Something went wrong';
      state.isLoading = false;
      state.data = null;
    });
  },
});

export default sfcUserBasicInfo.reducer;
