

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const baseURL = "https://keaexpenseapi.kea-sfe.com/api/ExpenseIdCreation";
// const baseURL = "http://192.168.1.243:1105/api/ExpenseIdCreation";


// const defaultParams = {
//     status: "D",
//     employeeId: 9408,
//     isFareAuto: 0,
//     companyId: 25,
//     stpfcId: 1586
// }

export const postSfcSaveTravelExpence = createAsyncThunk("postSfcSaveTravelExpence", async (data,{ rejectWithValue }) => {
    try {
         const res = await axios.post(`${baseURL}/SFCAdd`,data)
         return res
    } catch (error) {
        return rejectWithValue(error?.response?.data || {status: 500})
    }
});

export const updateSfcTravelExpense = createAsyncThunk("updateSfcTravelExpense", async (editData) => {
    try {
        // const params = {...editData};
        const selectedTerritoryId = editData.fromTerritoryId;
        // const stpfcDetailId = editData.stpfcDetailId;
        // const transportId = Number(editData.transportId);
        // const fromTerritoryId = editData.fromTerritoryId;
        // const toTerritoryId = editData.toTerritoryId;

        // delete params.stpfcDetailId;
        // delete params.twoWayDistance;
        // delete params.twoWayFare;
        // delete defaultParams.isFareAuto;
        // // delete defaultParams.status
        // delete defaultParams.employeeId
        // delete params.transportMode;
        // delete params.fromTerritory;
        // delete params.toTerritory;
        // delete params.Total;


        // const data = { ...params, transportId, fromTerritoryId, toTerritoryId,selectedTerritoryId,stpfcDetailId};
        return axios.put(`${baseURL}/SFCEditSave`,{...editData, selectedTerritoryId})
    } catch (error) {
        console.log(error);
    }
})

export const sfcSaveTravelExpence = createSlice({
    name: "sfcSaveTravelExpence",
    initialState: {
        isLoading: false,
        data: null,
        apiError: "",
        isSuccess: false
    },
    reducers: {
        clearState: (state) => {
            state.apiError = "",
            state.data = null,
            state.isLoading = false
        },
        updateSaveSuccess: (state, action) => {
            state.isSuccess = action.payload
        },
        clearApiStatus: (state) => {
            state.apiError = ""
        }
    },
    extraReducers: (builder) => {
        builder.addCase(postSfcSaveTravelExpence.pending , (state) => {
            state.isLoading = true
            state.isSuccess = false
        })

        builder.addCase(postSfcSaveTravelExpence.fulfilled, (state, action) => {
            state.isLoading = false;
            state.data = action.payload.data;
            state.isSuccess = true
        })
        builder.addCase(postSfcSaveTravelExpence.rejected, (state, action) => {
            state.apiError = action?.payload?.statusCode === 409 ? action.payload?.sfadd?.result : "Couldn't connect with server at this moment, please try again later.."
            state.isLoading = false
            state.data = null
            state.isSuccess = false
        })

        builder.addCase(updateSfcTravelExpense.pending , (state) => {
            state.isLoading = true
            state.isSuccess = false
        })

        builder.addCase(updateSfcTravelExpense.fulfilled, (state, action) => {
            state.isLoading = false;
            state.data = action.payload.data;
            state.isSuccess = true
        })
        builder.addCase(updateSfcTravelExpense.rejected, (state) => {
            state.apiError = "Something went wrong"
            state.isLoading = false
            state.data = null
            state.isSuccess = false
        })

    },
})
export const { clearState, updateSaveSuccess, clearApiStatus } = sfcSaveTravelExpence.actions;
export default sfcSaveTravelExpence.reducer;