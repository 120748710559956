import React from 'react';

const Btn = ({ isSelected = true, index, text, onClick }) => {
  return (
    <button
      style={{ flex: index === 0 ? '2' : '' }}
      className={`py-1 px-4 border border-primary rounded-full bg-${
        isSelected ? 'primary' : 'white'
      } text-${isSelected ? 'white' : 'black'} whitespace-nowrap`}
      onClick={onClick}
    >
      <p>{text}</p>
    </button>
  );
};

export default Btn;
