import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios, { all } from 'axios';

const baseURL = 'https://keaexpenseapi.kea-sfe.com/api/ExpenseIdCreation';
// const baseURL = "http://192.168.1.243:1105/api/ExpenseIdCreation";
// cityId=4838&effectiveDate=2024-08-25
// const defaultParams = {
//   employeeId: 9408,
// };
// {
//   "distance": 0,
//   "employeeId": 0,
//   "effectiveDate": "0001-01-01T00:00:00",
//   "specification": null,
//   "totalFare": 0
// }

export const getFareAndAllowanceForDistance = createAsyncThunk(
  'getFareAndAllowanceForDistance',
  (params) => {
    return axios.get(`${baseURL}/SFCFareAndAllowanceForDistance`, { params });
  }
);

export const getSfcAllowance = createAsyncThunk(
  'getSfcOneDistance',
  async (params) => {
    return axios.get(`${baseURL}/SFCAllowance`, {
      params,
    });
  }
);

export const fetchSfcFareAndAllowance = createAsyncThunk(
  'fetchSfcFareAndAllowance',
  async (sfcForm1) => {
    return axios.get(`${baseURL}/SFCFareAndAllowance`, {
      params: {
        gradeId: 1,
        ...sfcForm1,
      },
    });
  }
);

export const sfcFareAndAllowance = createSlice({
  name: 'SfcFareAndAllowance',
  initialState: {
    isLoading: false,
    fareAndAllowance: null,
    allowance: null,
    apiError: '',
    allowanceValue: 0,
  },
  reducers: {
    updateAllowance: (state, action) => {
      state.allowanceValue = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSfcFareAndAllowance.pending, (state, action) => {
      state.isLoading = true;
      state.fareAndAllowance = null;
    });
    builder.addCase(fetchSfcFareAndAllowance.fulfilled, (state, action) => {
      state.isLoading = false;
      state.fareAndAllowance = action.payload.data;
      state.apiError = false;
    });
    builder.addCase(fetchSfcFareAndAllowance.rejected, (state, action) => {
      state.isLoading = false;
      state.fareAndAllowance = null;
      state.apiError = 'Something is Wrong';
    });

    builder.addCase(getSfcAllowance.pending, (state, action) => {
      state.isLoading = true;
      state.allowance = null;
    });
    builder.addCase(getSfcAllowance.fulfilled, (state, action) => {
      state.isLoading = false;
      state.allowance = action.payload.data;
      state.apiError = false;
    });
    builder.addCase(getSfcAllowance.rejected, (state, action) => {
      state.isLoading = false;
      state.allowance = null;
      state.apiError = 'Something is Wrong';
    });

    builder.addCase(getFareAndAllowanceForDistance.pending, (state, action) => {
      state.isLoading = true;
      state.fareAndAllowance = null;
    });
    builder.addCase(
      getFareAndAllowanceForDistance.fulfilled,
      (state, action) => {
        state.isLoading = false;
        state.fareAndAllowance = action.payload.data;
        state.apiError = false;
      }
    );
    builder.addCase(
      getFareAndAllowanceForDistance.rejected,
      (state, action) => {
        state.isLoading = false;
        state.allowance = null;
        state.apiError = 'Something is Wrong';
      }
    );
  },
});
export const { updateAllowance } = sfcFareAndAllowance.actions;
export default sfcFareAndAllowance.reducer;
