import React, { useRef, useState } from 'react';
import { Camera } from 'react-camera-pro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { captureImage, setFileCapture } from '../../redux/slice/cameraSlice';
import { useNavigate } from 'react-router-dom';
const CameraOption = () => {
  const [isFlashOn, setIsFlashOn] = useState(false);

  const navigate = useNavigate();
  const camera = useRef(null);
  const dispatch = useDispatch();


  const handleTakePhoto = async () => {
    if (camera.current) {
      try {
        const photo = await camera.current.takePhoto();
        dispatch(captureImage(photo));
      } catch (error) {
        console.error('Error taking photo:', error);
      }
    }
  };

  const handleSwitchCamera = async () => {
    if (camera.current) {
      try {
        const switchCamera = await camera.current.switchCamera();
        console.log('switchCamera', switchCamera);
      } catch (error) {
        console.error('Error switching camera:', error);
      }
    }
  };

  const handleTorchLight = async () => {
    if (camera.current) {
      try {
        const toggleTorch = await camera.current.toggleTorch();
        console.log('toggleTorch', toggleTorch);
        setIsFlashOn(!isFlashOn);
      } catch (error) {
        console.error('Error toggling torch:', error);
      }
    }
  };
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        dispatch(setFileCapture(reader.result));
      };
      reader.readAsDataURL(file);
      navigate('/capture-image');
    }
  };

  return (
    <div className="fixed inset-0 flex flex-col items-center justify-center">
      <Camera
        ref={camera}
        facingMode="environment"
        aspectRatio="cover"
        className="w-full h-full"
        errorMessages={{
          noCameraAccessible:
            'No camera device accessible. Please connect your camera or try a different browser.',
          permissionDenied:
            'Permission denied. Please refresh and give camera permission.',
          switchCamera:
            'It is not possible to switch camera to different one because there is only one video device accessible.',
          canvas: 'Canvas is not supported.',
        }}
        videoReadyCallback={() => console.log('Video feed ready.')}
      />

      <div className="absolute bottom-0 flex flex-row items-center space-y-4 p-4 gap-8 w-full pl-20 bg-black">
        <div className="flex items-center justify-center">
          <label
            htmlFor="dropzone-file"
            className="flex flex-col items-center justify-center w-16 h-16 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 hover:bg-gray-100"
          >
            <div className="flex flex-col items-center justify-center pt-5 pb-6">
              <svg
                className="w-8 h-8 text-gray-500"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 16"
                role="img"
                aria-label="Upload Image"
              >
                <path
                  stroke="currentColor"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                />
              </svg>
            </div>
            <input
              id="dropzone-file"
              type="file"
              className="hidden"
              onChange={handleFileChange}
            />
          </label>
        </div>
        <Link to={'/capture-image'}>
          <button
            className="bg-indigo-200 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full h-20 w-20"
            onClick={handleTakePhoto}
          >
            <FontAwesomeIcon icon="fars fa-circle" color="white" size="3x" />
          </button>
        </Link>
        <button
          className=" text-white font-bold py-2 px-4 rounded-full h-20 w-20"
          onClick={handleSwitchCamera}
        >
          <FontAwesomeIcon icon="fa-solid fa-arrows-rotate" size="2x" />
        </button>
      </div>
      <div className='bottom-0 w-full'>
        <div className="absolute top-0 right-0 m-4">
          <button
            className={`${isFlashOn ? 'bg-red-700' : ''} text-white font-bold py-2 px-4 rounded-full`}
            onClick={handleTorchLight}
          >
            <FontAwesomeIcon
              icon="fa-solid fa-lightbulb"
              color="yellow"
              size="2x"
            />
          </button>
        </div>

        <div className="absolute top-0 left-0 m-4">
          <Link to={'/'}>
            <button
              className="hover:bg-red-700 text-white font-bold py-2 px-4 rounded-full"
            >
              <FontAwesomeIcon
                icon="fa-solid fa-xmark"
                size="2x"
                color="gray-900"
              />
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default CameraOption;
