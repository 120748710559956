import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

const baseURL = 'https://keaexpenseapi.kea-sfe.com/api/ExpenseIdCreation';
// const baseURL = "http://192.168.1.243:1105/api/ExpenseIdCreation";

// const defaultParams = {
//  
// };
// {stpfcDetailIds: data,}

export const postSfcSubmitTravelExpence = createAsyncThunk(
  'postSfcSubmitTravelExpence',
  async (data) => {
    return axios.post(`${baseURL}/SFCSubmit`, data);
  }
);

export const sfcSubmitTravelExpence = createSlice({
  name: 'sfcSubmit',
  initialState: {
    isLoading: false,
    data: null,
    apiError: '',
  },
  extraReducers: (builder) => {
    builder.addCase(postSfcSubmitTravelExpence.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(postSfcSubmitTravelExpence.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(postSfcSubmitTravelExpence.rejected, (state) => {
      state.apiError = 'Something went wrong';
      state.isLoading = false;
      state.data = null;
    });
  },
});

export default sfcSubmitTravelExpence.reducer;
