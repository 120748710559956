import React, { useEffect, useState } from 'react';
import Footer from '../../components/footer/Footer';
import { useDispatch, useSelector } from 'react-redux';
import { fetchMyExpenseDashboard, getMyExpenseDashboard } from '../../redux/slice/myExpenseSlice';
import Loader from '../../components/modal/Loader';
import SideBar from '../../components/sidebar/SideBar';

const Home = () => {
  const [homeExpense, setHomeExpense] = useState([]);

  const dispatch = useDispatch();
  const { isLoading, apiError, myExpenseDashboardData } = useSelector(getMyExpenseDashboard);
  console.log('myExpenseData', isLoading, apiError, myExpenseDashboardData);

  useEffect(() => {
    // Dispatch the action to fetch expense data
    dispatch(fetchMyExpenseDashboard());
  }, [dispatch]);

  useEffect(() => {
    // Only set the homeExpense state if myExpenseData has changed
    if (myExpenseDashboardData && myExpenseDashboardData !== homeExpense) {
      setHomeExpense([myExpenseDashboardData]);
    }
  }, [myExpenseDashboardData]);

  console.log('homeExpense//////', homeExpense);

  return (
    <>
      <div className="pr-4 pl-4  w-screen">      {/* h-screen */}
        {isLoading ? (
          <div className="flex items-center justify-center h-screen">
            <Loader />
          </div>
        ) : apiError ? (
          <div className="flex items-center justify-center h-screen">
            <p className="text-red-500 text-xl">{apiError}</p>
          </div>
        ) : (
          homeExpense &&
          homeExpense.length > 0 &&
          homeExpense.map((item, index) => {
            const {
              total,
              allowances,
              previousMonthTotal,
              secondLastYear,
              secondLastMonth,
              previousMonthStatus,
              secondLastTotal,
              secondLastStatus,
              travelExpense,
              misExpense,
              foodExpense,
              accomodationExpense,
            } = item;

            return (
              <div key={index}>
                <SideBar />
                <div className=" h-40 w-full rounded-md p-4 flex flex-col justify-between text-left" style={{ backgroundColor: '#A04D95' }}>
                  <h6 className="text-yellow-400 text-xl">Expenses</h6>
                  <h1 className="text-3xl text-white bold">INR {total}</h1>
                  <p className="text-white">Current Month Expense</p>
                </div>

                <div className="flex justify-between mt-8">
                  <h6 className="text-2xl font-bold text-center">All Expense</h6>
                  <button className="bg-gray-200 px-4 py-2 rounded-md" style={{ color: '#A04D95' }}>
                    View More
                  </button>
                </div>

                <div className="flex mt-3 justify-between bg-gray-200 p-4 rounded-md">
                  <p>Previous month</p>
                  <div>
                    <p className="text-sm">{previousMonthStatus || 'Pending'}</p>
                    <p>INR {previousMonthTotal}</p>
                  </div>
                </div>

                <div className="flex mt-3 justify-between bg-gray-200 p-4 rounded-md">
                  <p>
                    {secondLastMonth} - {secondLastYear}
                  </p>
                  <div>
                    <p className="text-sm">{secondLastStatus || 'Pending'}</p>
                    <p>INR {secondLastTotal}</p>
                  </div>
                </div>

                <div className="flex justify-between mt-8">
                  <h6 className="text-2xl font-bold text-center">Bill</h6>
                  <button className="bg-gray-200 px-4 py-2 rounded-md" style={{ color: '#A04D95' }}>
                    View More
                  </button>
                </div>

                <div className="flex mt-3 justify-between bg-gray-200 p-4 rounded-md">
                  <p>Travel</p>
                  <div>
                    <p>INR {travelExpense}</p>
                  </div>
                </div>

                <div className="flex mt-3 justify-between bg-gray-200 p-4 rounded-md">
                  <p>Food</p>
                  <div>
                    <p>INR {foodExpense}</p>
                  </div>
                </div>

                <div className="flex mt-3 justify-between bg-gray-200 p-4 rounded-md">
                  <p>Miscellaneous</p>
                  <div>
                    <p>INR {misExpense}</p>
                  </div>
                </div>

                {/* <div className="flex mt-3 justify-between bg-gray-200 p-4 rounded-md">
                  <p>Accommodation</p>
                  <div>
                    <p>INR {accomodationExpense}</p>
                  </div>
                </div> */}

                {/* <div className="flex mt-3 justify-between bg-gray-200 p-4 rounded-md">
                  <p>Allowance</p>
                  <div>
                    <p>INR {allowances}</p>
                  </div>
                </div> */}
              </div>
            );
          })
        )}
      </div>

      <Footer />
    </>
  );
};

export default Home;
