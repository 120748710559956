import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getExpenseType } from '../../api/cameraApi'
import { baseURL } from '../../utils/Constant'
import axios from 'axios'






// export const expenseSubmit = async (data) => {
//   try {
//     console.log('data Camera API', data);
//     const uploadResponse = await axios.post(
//       `${baseUrl}/ExpenseUploadTemp`,
//       data,
//       {
//         headers: {
//           'Content-Type': 'multipart/form-data',
//         },

//       }
//     );
//     // console.log('File upload response:', uploadResponse);
//     return uploadResponse.data;
//   } catch (error) {
//     console.error('Error fetching expense type:', error);
//     return null;
//   }
// }

// const setUnReportedExpense = createAsyncThunk("unreported/setUnReportedExpense", async (data) => {
//   try {
//     const response = await axios.post(`${baseURL}/GetExpenseUploadTemp`, data);
//     return response.data;
//   } catch (error) {
//     return null;

//   }
// })


// const fetchData = async () => {
//   try {
//     const response = await axios.get(`${baseURL}/GetExpenseUploadTemp?employeeId=8271&companyId=1`, {
//       headers: {
//         'Cache-Control': 'no-cache',
//       },
//     });
//     console.log('Fetched data:', response.data.expenseUploadTempList);
//     setLocalUnReportedList(response.data.expenseUploadTempList); // Update local state
//   } catch (error) {
//     console.error('Error fetching unreported expenses:', error);
//   }
// };

// const getUnReportedExpense = createAsyncThunk("unreported/getUnReportedExpense", async () => {
//   try {
//     const response = await axios.get(`${baseURL}/GetExpenseUploadTemp?employeeId=8271&companyId=1`, {
//       headers: {
//         'Cache-Control': 'no-cache',
//       },
//     });
//     console.log('Fetched data:1', response.data.expenseUploadTempList);
//     return response.data.expenseUploadTempList;
//   } catch (error) {
//     console.error('Error fetching unreported expenses:', error);
//   }
// })




const initialState = {
  image: '',
  imageFile: '',
  cropImage: '',
  currentImage: '',
  expenseType:[],
  unReportedList:[],
  file: null

}

export const cameraSlice = createSlice({
  name: 'camera',
  initialState,
  reducers: {
    captureImage: (state,{payload}) => {
      state.image = payload
    },
    captureImageFile: (state,{payload}) => {
      state.imageFile = payload
    },
    captureCropImage: (state,{payload}) => {
      console.log("Crop  image Redux",payload);
      state.cropImage = payload
    },
    currentImage: (state,{payload}) => {
      state.currentImage = payload
    },
    expenseType: (state,{payload}) => {
      state.expenseType = payload
    },
    unReportDetails: (state,{payload}) => {
      state.unReportedList = payload
    },
    setFileCapture: (state,{payload}) => {
      state.file = payload
    }
  },



  extraReducers: (builder) => {
    builder
      // .addCase(getExpenseType.pending, (state) => {
      //   state.isLoading = true
      // })
      // .addCase(getExpenseType.fulfilled, (state, { payload }) => {
      //   state.isLoading = false
      //   state.expenseType = payload
      //   state.error = null
      // })
      // .addCase(getExpenseType.rejected, (state, { payload }) => {
      //   state.isLoading = false
      //   state.error = payload
      // })

      //setUnReportedExpense
      // .addCase(setUnReportedExpense.pending, (state) => {
      //   state.isLoading = true
      // })
      // .addCase(setUnReportedExpense.fulfilled, (state, { payload }) => {
      //   state.isLoading = false
      //   state.unReportedList = payload
      //   state.error = null
      // })
      // .addCase(setUnReportedExpense.rejected, (state, { payload }) => {
      //   state.isLoading = false
      //   state.error = payload
      // })

  }
})

export const { captureImage ,
  captureImageFile,captureCropImage,currentImage,
  expenseType,unReportDetailsm, setFileCapture,unReportDetails} = cameraSlice.actions



export const getAllExpenseType = (state) => state.camera.expenseType
export const getUnReportedExpense = (state) => state.camera.unReportedList
export const getFileCapture = (state) => state.camera.file
export const getUnReportedExpenseList = (state) => state.camera.unReportedList

export default cameraSlice.reducer