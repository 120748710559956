import React, { useState } from 'react';
import './style.css';

//varient values should be animated , plane , vertical
const Input = ({
  label,
  name,
  value,
  varient = 'animated',
  disabled,
  error,
  className,
  ...restProps
}) => {
  const [isFocused, setIsFocused] = useState(false);
  label = label || "Enter " + name 

  return (
    <div
      className={`custom-input mt-4 ${varient === "vertical" ? "flex flex-col gap-2 mt-0" : "relative"}  ${ error ? "form-error" : null}`}
      onClick={() => disabled || setIsFocused(true)}
    >
      {varient !== 'plane' ? (
        <label
          htmlFor={name}
          className={`input-label font-medium ${
            isFocused || value !== 'undefined' && value !== ''
              ? 'focused'
              : 'text-secondary-200'
          } ${varient !== 'plane' ? `${varient === "vertical" ? "text-[20px]" : "absolute text-[18px]"} ` : 'static'} ${ disabled ? 'text-disable-200' :
            isFocused
              ? 'text-primary'
              : error
              ? 'text-red-600'
              : value !== 'undefined' && value !== ''
              ? 'text-primary'
              : 'text-secondary'
          }`}
        >
          {label}
        </label>
      ) : null}
      <input
      placeholder={varient === "plane" ? label : ""}
        name={name}
        value={value}
        disabled={disabled}
        id={name}
        type="text"
        className={`w-full p-3 mt-2 text-lg border px-4 rounded ${
          isFocused
            ? 'border-primary focused'
            : disabled
            ? 'text-disable-200 bg-none border-disable'
            : error
            ? 'border-red-700'
            : 'border-secondary-200'
        } outline-none ${className}`}
        onBlur={() => {
          setTimeout(() => {
            setIsFocused(false);
          }, 100);
        }}
        {...restProps}
      />
    </div>
  );
};

export default Input;
