import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const FileAttachedModal = ({ isOpen, onClose, showModal }) => {
  const [selectedImages, setSelectedImages] = useState([]);
  const navigate = useNavigate();

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    setSelectedImages(files);
  };

  const handleNavigate = (path) => {
    navigate(path, { state: true }); // Pass true as state
    onClose();
  };

  return (
    <>
      {showModal && (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              {/*content*/}
              <div className="bg-gray-200 h-44 w-60 border-0 rounded-xl shadow-lg relative flex flex-col outline-none focus:outline-none text-black">
                {/*header*/}
                <div className="flex ml-3 justify-between p-2 border-b border-solid rounded-t">
                  <button
                    onClick={() => handleNavigate('/un-reported-expenses')}
                    className="flex"
                  >
                    <FontAwesomeIcon icon="fa-solid fa-circle-exclamation" />
                    <p className="pl-3 font-bold">Unreported Expenses</p>
                  </button>
                </div>
                <div className="flex ml-3 justify-between p-2 border-b border-solid rounded-t">
                  <button
                    onClick={() => handleNavigate('/camera')}
                    className="flex"
                  >
                    <FontAwesomeIcon
                      icon="fat-thin fa-expand"
                      color="gray-200"
                      size="lg"
                    />
                    <p className="pl-3 font-bold">Invoice Scan</p>{' '}
                  </button>
                </div>
                <div className="flex ml-3 justify-between p-2 border-b border-solid rounded-t">
                  <input
                    type="file"
                    id="fileInput"
                    style={{ display: 'none' }}
                    accept="image/*"
                    multiple
                    onChange={handleFileChange}
                  />
                  <button
                    onClick={() => document.getElementById('fileInput').click()}
                    className="flex"
                  >
                    <FontAwesomeIcon icon="far-regular fa-image" />
                    <p className="pl-3 font-bold">Photo</p>
                  </button>
                </div>
                <div className="flex ml-3 justify-between p-2 border-b border-solid rounded-t">
                  <input
                    type="file"
                    id="fileInput1"
                    accept=".pdf"
                    style={{ display: 'none' }}
                    multiple
                    onChange={handleFileChange}
                  />
                  <button
                    onClick={() =>
                      document.getElementById('fileInput1').click()
                    }
                    className="flex"
                  >
                    <FontAwesomeIcon icon="fa-solid fa-folder" />
                    <p className="pl-3 font-bold">Gallery</p>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 "></div>
        </>
      )}
    </>
  );
};

export default FileAttachedModal;
