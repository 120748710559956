import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { baseURL } from '../../utils/Constant';

export const fetchUnReportedData = createAsyncThunk(
  'unReported/fetchUnReportedData',
  async () => {
    console.log('fetchUnReportedData');
    try {
      const response = await axios.get(
        `${baseURL}/GetExpenseUploadTemp?employeeId=8271&companyId=1`,
        {
          headers: {
            'Cache-Control': 'no-cache',
          },
        }
      );
      console.log('Fetched data:1', response.data.expenseUploadTempList);
      return response.data.expenseUploadTempList;
    } catch (error) {
      console.log('error', error.message);
      return null;
    }
  }
);

// modify route data submit  setExpenseModifySubmit
export const setSelectedAttachment = createAsyncThunk("unReported/setSelectedAttachment", async (data) => {
  console.log('data', data);
  try {
    const response = await axios.post(`${baseURL}/expenseGetFileAttachement`, data);
    console.log('response', response.data);
    return response.data;
  } catch (error) {
    return null;
  }
});



const unReportedSlice = createSlice({
  name: 'unReported',
  initialState: {
    isLoading: false,
    unReportedData: [],
    selectedAttachment: [],
    error: null,
  },
  reducers: {
    // setSelectedAttachment(state, { payload }) {
    //   state.selectedAttachment = [...state.selectedAttachment, payload];
    // },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUnReportedData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchUnReportedData.fulfilled, (state, { payload }) => {
        console.log('payload', payload);
        state.isLoading = false;
        state.unReportedData = payload;
        state.error = null;
      })
      .addCase(fetchUnReportedData.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload;
      });
  },
});

export const setSelectedAttachmentReducer = unReportedSlice.actions;
export const getSelectedAttachment = (state) =>state.unReported.selectedAttachment;
export const getAllUnreportedExpense = (state) =>state.unReported;
export default unReportedSlice.reducer;
