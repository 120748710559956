import React, { useState, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';

const Dropdown = ({
  label = 'Select an option',
  options = [],
  name,
  attributeName = '',
  onChange,
  dropdownHeight = 'h-10',
  dropdownWidth = 'w-44',
  attributeValue = '',
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState(label);

  const handleToggle = () => setIsOpen(!isOpen);

  const handleSelect = useCallback(
    (option) => {
      setSelected(option[attributeName] || option);
      onChange({ [name]: option[attributeValue] || option });
      setIsOpen(false);
    },
    [attributeName, name, onChange]
  );

  return (
    <div className={`relative inline-block text-left  ${dropdownWidth}`}>
      <button
        type="button"
        onClick={handleToggle}
        className={`block ${dropdownWidth} ${dropdownHeight} px-3 py-2 text-sm font-medium text-gray-700 border border-gray-300  rounded-md shadow-sm bg-white hover:bg-gray-50 focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 text-left flex justify-between items-center`}
      >
        <span>{selected}</span>
        <FontAwesomeIcon icon={isOpen ? faChevronUp : faChevronDown} className="ml-2" />
      </button>
      {isOpen && (
        <div
          className={`absolute z-10 ${dropdownWidth} mt-1 bg-white border border-gray-300 rounded-md shadow-lg`}
        >
          {options && options.length > 0 && options.map((option, index) => (
            <button
              key={index}
              onClick={() => handleSelect(option)}
              className="block w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 text-left"
            >
              {attributeName ? option[attributeName] : option}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default Dropdown;
