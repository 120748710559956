import React, { useEffect, useState, useRef } from 'react';
import MyExpenseList from './MyExpenseList';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import Button from '../../components/button/Button';
import { useDispatch, useSelector } from 'react-redux';
import Modal from '../../components/modal/Modal.jsx';
import Loader from '../../components/modal/Loader.jsx';
import {
  fetchMyExpenseData,
  getMyExpenseData,
  fetchExpenseCityFilter,
  getExpenseCityFilter,
  setExpenseFinalSubmit,
  getFinalSubmitExpense,
} from '../../redux/slice/myExpenseSlice';

const MyExpense = () => {
  const [selectedFilter, setSelectedFilter] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const timerRef = useRef(null);

  const dispatch = useDispatch();
  const { isLoading, errorMessage, apiError, myExpenseData } = useSelector(getMyExpenseData);
  const cityFilter = useSelector(getExpenseCityFilter);
  const finalSubmitExpense = useSelector(getFinalSubmitExpense);

  useEffect(() => {
    dispatch(fetchMyExpenseData());
    dispatch(fetchExpenseCityFilter());
  }, [dispatch]);

  const handleFilter = (filter) => {
    setSelectedFilter((prevFilter) => (prevFilter === filter ? '' : filter));
  };

  const filteredExpenses = selectedFilter
    ? myExpenseData.filter(
        (item) =>
          item.status === selectedFilter ||
          item.fromCity === selectedFilter ||
          item.toCity === selectedFilter
      )
    : myExpenseData;

  const handlerExpenseSubmit = () => {
    setModalOpen(true);
    dispatch(setExpenseFinalSubmit());
  };
  const closeModal = () => {
    setModalOpen(false);
  };

  const filteredCityStatusName = ['Unsubmitted', 'Submitted', ...cityFilter.map((item) => item.cityName)];

  return (
    <div className="w-screen flex flex-col">
      {isLoading ? (
        <Loader />
      ) : apiError ? (
        <Modal message={errorMessage} type="Fail" isOpen={true} />
      ) : (
        <>
          {/* Header */}
          <div className="flex justify-center items-center w-full h-10 relative p-4 mt-6 mb-2">
            <Link to="/home" className="absolute left-4">
              <FontAwesomeIcon
                icon="fa-solid fa-angle-left"
                size="lg"
                color="#A04D95"
              />
            </Link>
            <h1 className="text-2xl font-bold" style={{ color: '#A04D95' }}>
              My Expense
            </h1>
          </div>

          {/* Filter Buttons */}
          <div className="flex flex-row justify-start items-center mt-2 space-x-2 overflow-x-auto no-scrollbar pb-3 pt-2 pl-4">
            {filteredCityStatusName?.map((data, index) => (
              <button
                key={index}
                className={`rounded-xl px-4 py-2 border-2 text-center whitespace-nowrap ${
                  selectedFilter === data ? 'bg-primary text-white' : 'bg-white'
                } transition-all duration-200`}
                onClick={() => handleFilter(data)}
              >
                {data}
              </button>
            ))}
          </div>

          <div className="divider-y h-[2px] w-full bg-gray-300 mt-2"></div>

          {/* Expense List or No Data */}
          {filteredExpenses.length === 0 ? (
            <div className="flex items-center justify-center mt-56">
              <h3
                className="text-center mt-20 font-bold"
                style={{ color: '#A04D95' }}
              >
                No Data Found
              </h3>
            </div>
          ) : (
            <div className="flex-1 overflow-y-auto mt-2">
              {filteredExpenses.map((item, index) => (
                <div key={index}>
                  <MyExpenseList id={index} item={item} isMyExpense={true} />
                  <div className="divider-y h-1 w-full bg-gray-300"></div>
                </div>
              ))}
            </div>
          )}

          {/* Submit Button */}
          <div className="fixed bottom-0 flex items-center justify-center w-full bg-gray-200 p-4">
            <Button
              type="button"
              btFullwidth={true}
              label="Submit"
              onClick={handlerExpenseSubmit}
              btBgColor="#A04D95"
              btTextColor="text-white"
            />
          </div>

          {/* Modal */}
          {finalSubmitExpense && (
            <Modal
              time={2000} //  3 seconds
              isOpen={modalOpen}
              message={finalSubmitExpense?.message}
              type={finalSubmitExpense?.status}
              onClose={closeModal}
            />
          )}
        </>
      )}
    </div>
  );
};

export default MyExpense;
