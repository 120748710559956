import React, { useEffect, useState } from 'react';
import Dropdown from '../../components/dropdown/Dropdown';
import Input from '../../components/input/Input';
import Textarea from '../../components/textarea/Textarea';
import Button from '../../components/button/Button';
import { getExpenseActivity, getCityList } from '../../api/index';
import { priodSatate } from '../../utils/Constant';
import { setExpenseModifySubmit } from '../../redux/slice/myExpenseSlice';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
const ModifyRoutes = ({ isOpen, onClose }) => {
  const dispatch = useDispatch();
  const [showTable, setShowTable] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [activityList, setActivityList] = useState([]);
  const [cityList, setCityList] = useState([]);

  const [modifyRoutesData, setModifyRoutesData] = useState({
    employeeId: 9394,
    expenseId: 5842,
    activityDate: '2024-06-07', // change the date each and every time of api
    period: '',
    activity: '',
    companyId: 25,
    isStay: 0,
    remarks: '',
    tblRoute: [],
  });

  useEffect(() => {
    if (isOpen) {
      fetchActivityList();
      fetchCityList();
    }
  }, [isOpen]);

  const fetchActivityList = async () => {
    try {
      const { getExpenseActivityList } = await getExpenseActivity();
      setActivityList(getExpenseActivityList);
    } catch (error) {
      console.error('Error fetching activities:', error);
    }
  };

  const fetchCityList = async () => {
    try {
      const { getExpenseCityList } = await getCityList();
      setCityList(getExpenseCityList);
    } catch (error) {
      console.error('Error fetching cities:', error);
    }
  };

  const handleAdd = (event) => {
    event.preventDefault();
    const { period, activity, fromCity, toCity } = modifyRoutesData;

    let newFromCity = '';
    let newToCity = '';
    let newPeriod = '';
    let newActivity = '';
    if (fromCity) {
      newFromCity = cityList.find((item) => item.cityId === fromCity)?.cityName;
    }
    if (toCity) {
      newToCity = cityList.find((item) => item.cityId === toCity)?.cityName;
    }
    if (period) {
      newPeriod = priodSatate.find((item) => item.value === period)?.periodName;
    }
    if (activity) {
      newActivity = activityList.find(
        (item) => item.activityTypeId === activity
      )?.activityName;
    }

    setTableData((prev) => [
      ...prev,
      {
        period: newPeriod,
        activity: newActivity,
        fromCity: newFromCity,
        toCity: newToCity,
      },
    ]);
    setModifyRoutesData((prev) => ({
      ...prev,
      period: String(period),
      activity: String(activity),
      tblRoute: [
        ...prev.tblRoute,
        {
          Slno: String(prev.tblRoute.length + 1),
          ActTypeId: String(prev.tblRoute.length + 1),
          Period: String(period),
          FromCity: String(fromCity),
          ToCity: String(toCity),
        },
      ],
    }));
    delete modifyRoutesData.fromCity;
    delete modifyRoutesData.toCity;
    setShowTable(true);
  };

  const handleDeleteRow = (index) => {
    setTableData((prev) => prev.filter((_, i) => i !== index));
    setShowTable(tableData.length > 1);
  };

  const handleModifyRouteSelector = (data) => {
    setModifyRoutesData((prev) => ({ ...prev, ...data }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(setExpenseModifySubmit(modifyRoutesData));
  };

  const thAndTrClass = 'border border-slate-600';
  return (
    <div>
      <div
        className={`fixed inset-0 bg-gray-100 bg-opacity-50 transition-opacity ${
          isOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'
        }`}
        onClick={onClose}
      ></div>

      <div
        className={`fixed bottom-0 left-0 w-full shadow-lg transition-transform transform p-4 bg-gray-200 rounded-t-[3rem] h-[85vh]  ${
          isOpen ? ' translate-y-0' : 'translate-y-full'
        }`}
      >
        <button
          className="absolute top-4 right-4 text-primary text-3xl text-bond"
          onClick={onClose}
        >
          &times;
        </button>
        <form onSubmit={handleAdd}>
          <div className="flex  justify-between align-items-center w-full gap-1 mt-6">
            <div className="w-full">
              <p>Period</p>
              <Dropdown
                label="Select"
                name="period"
                value={modifyRoutesData.period}
                options={priodSatate}
                attributeValue="value"
                attributeName="periodName"
                onChange={handleModifyRouteSelector}
              />
            </div>
            <div className="w-full">
              <p>Activity</p>
              <Dropdown
                label="Select"
                name="activity"
                attributeValue="activityTypeId"
                value={modifyRoutesData.activity}
                options={activityList}
                attributeName="activityName"
                onChange={handleModifyRouteSelector}
              />
            </div>
          </div>
          <div className="flex justify-between align-items-center w-full gap-1 mt-6">
            <div className="w-full">
              <p>From City</p>
              <Dropdown
                label="Select"
                name="fromCity"
                attributeName="cityName"
                options={cityList}
                attributeValue="cityId"
                onChange={handleModifyRouteSelector}
              />
            </div>
            <div className="w-full">
              <p>To City</p>
              <Dropdown
                attributeValue="cityId"
                label="Select"
                name="toCity"
                attributeName="cityName"
                options={cityList}
                onChange={handleModifyRouteSelector}
              />
            </div>
          </div>

          {/* Add */}
          <div className="rounded-2xl bg-primary flex justify-center align-items-end mt-4 p-2">
            <button className="text-white" type="submit">
              Add
            </button>
          </div>
        </form>

        {/* Table */}
        {showTable && tableData && tableData.length > 0 && (
          <div className="mt-4">
            <table className="table-auto border-collapse border-spacing-2 border border-slate-500">
              <thead>
                <tr>
                  <th className={thAndTrClass}>Sl No</th>
                  <th className={thAndTrClass}>Period</th>
                  <th className={thAndTrClass}>Activity</th>
                  <th className={thAndTrClass}>From City</th>
                  <th className={thAndTrClass}>To City</th>
                  {/* Added remarks column */}
                  <th className={thAndTrClass}>Action</th>
                </tr>
              </thead>
              <tbody>
                {tableData.map((item, index) => {
                  const { period, activity, fromCity, toCity } = item;
                  return (
                    <tr key={index} className={`${thAndTrClass} text-center`}>
                      <td className={thAndTrClass}>{index + 1}</td>
                      <td className={thAndTrClass}>{period}</td>
                      <td className={thAndTrClass}>{activity}</td>
                      <td className={thAndTrClass}>{fromCity}</td>
                      <td className={thAndTrClass}>{toCity}</td>
                      {/* Show remarks */}
                      <td
                        className={thAndTrClass}
                        onClick={() => handleDeleteRow(index)}
                      >
                        <FontAwesomeIcon icon="fa-solid fa-trash" color="#A04D95" />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}

        {/* Stayed Over Night */}
        <div className="mt-4">
          <p className="text-lg">Stayed Over Night</p>
          <div className="flex mt-2 space-x-4">
            <div className="flex items-center space-x-2">
              <input
                type="radio"
                id="yes"
                name="isStay"
                value={1}
                checked={modifyRoutesData.isStay == 1}
                onChange={(e) =>
                  setModifyRoutesData({
                    ...modifyRoutesData,
                    isStay: Number(e.target.value),
                  })
                }
                className="form-radio text-blue-500 focus:ring-blue-500"
              />
              <label htmlFor="yes" className="text-gray-700">
                Yes
              </label>
            </div>
            <div className="flex items-center space-x-2">
              <input
                type="radio"
                id="no"
                name="isStay"
                value={0}
                checked={modifyRoutesData.isStay == 0}
                onChange={(e) =>
                  setModifyRoutesData({
                    ...modifyRoutesData,
                    isStay: Number(e.target.value),
                  })
                }
                className="form-radio text-blue-500 focus:ring-blue-500"
              />
              <label htmlFor="no" className="text-gray-700">
                No
              </label>
            </div>
          </div>
        </div>

        {/* Remarks */}
        <div className="mt-4">
          <p className="mb-4">Remarks</p>
          <Textarea
            value={modifyRoutesData.remarks || ''} // Sets value based on state
            onChange={(e) =>
              setModifyRoutesData({
                ...modifyRoutesData,
                remarks: e.target.value,
              })
            } // Assigns event handler
            textAreaClassName="h-40 w-full border border-gray-400 rounded-md p-3"
            placeholder="Enter your text here..."
            name="remarks"
          />
        </div>

        {/* Footer Buttons */}
        <div className="flex items-center justify-between w-full gap-1 mt-8">
          <Button
            label="Close"
            btTextColor="text-primary"
            btBgColor="white"
            onClick={onClose}
          />
          <Button
            label="Submit"
            onClick={handleSubmit}
            btBgColor="bg-primary"
            btTextColor="text-white"
          />
        </div>
      </div>
    </div>
  );
};

export default ModifyRoutes;
