import { configureStore } from '@reduxjs/toolkit'
import cameraReducer from './slice/cameraSlice'
import sfcTravelingExpencesReducer from './slice/sfcTravelingExpences'
import sfcFareAndAllowanceReducer from './slice/sfcFareAndAllowance'
import sfcSaveTravelExpenceReducer from './slice/sfcSaveTravelExpence'
import sfcFormFiltersReducer from './slice/sfcFormFilters'
import unReportedReducer from './slice/unReportedSlice'
import  sfcDeleteExpenceReducer  from './slice/sfcTravelExpenseDelete'
import sfcOnewayDistanceReducer from './slice/sfcOnewayDistance'
import sfcTravelExpenseFormReducer from './slice/sfcTravelExpenseForm'
import myExpenseReducer from './slice/myExpenseSlice'
import sfcUserBasicInfoReducer from './slice/sfcUserBasicInfo'
import sfcCreationSubmitReducer from './slice/sfcCreationSubmit'
import {myExpenseDashboardReducer} from './slice/myExpenseSlice'
import {myExpenseDataSliceReducer} from './slice/myExpenseSlice'
// import {dayWiseExpenseReducer} from './slice/myExpenseSlice'
import {attachmentDayWiseReducer} from './slice/myExpenseSlice'
import { dayWiseTotalExpenseReducer } from './slice/myExpenseSlice'

export const store = configureStore({
  reducer: {
    camera: cameraReducer,
    sfcTravelingExpences: sfcTravelingExpencesReducer,
    sfcFareAndAllowance: sfcFareAndAllowanceReducer,
    sfcSaveTravelExpence: sfcSaveTravelExpenceReducer,
    sfcFormFilters: sfcFormFiltersReducer,
    unReported: unReportedReducer,
    myExpense: myExpenseReducer,
    myExpenseDashboard: myExpenseDashboardReducer,
    myExpenseData:myExpenseDataSliceReducer,
    attachmentDayWise: attachmentDayWiseReducer,
    dayWiseTotalExpense: dayWiseTotalExpenseReducer,
    // dayWiseExpense: dayWiseExpenseReducer
    sfcDeleteExpence: sfcDeleteExpenceReducer,
    sfcOnewayDistance: sfcOnewayDistanceReducer,
    sfcTravelExpenseForm: sfcTravelExpenseFormReducer,
    sfcUserBasicInfo : sfcUserBasicInfoReducer,
    sfcCreationSubmit: sfcCreationSubmitReducer
  },
})
