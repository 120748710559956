import { createSlice } from '@reduxjs/toolkit';

const initialSfcExpenseData = {
  fromTerritoryId: '',
  toTerritoryId: '',
  fromCityId: '',
  toCityId: '',
  categoryId: '',
  transportId: '',
  effectiveDate: '',
};

const editPayloadProcessor = (data) => ({
  stpfcId: data.stpfcId,
  fromTerritoryId: data.fromTerritoryId,
  toTerritoryId: data.toTerritoryId,
  fromCityId: data.fromCityId,
  toCityId: data.toCityId,
  categoryId: data.categoryId,
  transportId: data.transportId,
  effectiveDate: '',
  specification: data.specification,
  oneWayDistance: data.oneWayDistance,
  twoWayDistance: data.twoWayDistance,
  oneWayFare: data.oneWayFare,
  twoWayFare: data.twoWayFare,
  allowance: data.allowance,
  remarks: data.remarks,
  numberOfChemist: data.numberOfChemist,
  numberOfDoctors: data.numberOfDoctors,
  total: data.total,
  stpfcDetailId: data.stpfcDetailid,
  sequenceNo: data.sequenceNo,
});

export const sfcTravelExpenseForm = createSlice({
  name: 'SfcCity',
  initialState: {
    isLoading: false,
    expenseIdCreationFormData: initialSfcExpenseData,
    apiError: '',
    isEditingMode: false,
  },
  reducers: {
    getSfcTravelExpenseFormInitialState: (state) => {
      state.expenseIdCreationFormData = initialSfcExpenseData;
      state.isEditingMode = false;
    },
    upadateExpenseIdCreationFormDataForEdit: (state, action) => {
      
      state.expenseIdCreationFormData = editPayloadProcessor(action.payload);
      state.isEditingMode = true;
    },
    upadateExpenseIdCreationFormData: (state, action) => {
      state.expenseIdCreationFormData = {
        ...state.expenseIdCreationFormData,
        ...action.payload,
      };
    },
  },
});
export const {
  upadateExpenseIdCreationFormData,
  upadateExpenseIdCreationFormDataForEdit,
  getSfcTravelExpenseFormInitialState,
} = sfcTravelExpenseForm.actions;
export default sfcTravelExpenseForm.reducer;
