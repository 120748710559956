// Modal.js
import React, { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck, faCircleXmark } from '@fortawesome/free-solid-svg-icons';

const Modal = ({ isOpen, message, type, time, onClose }) => {
  useEffect(() => {
    if (isOpen && time) {
      const timer = setTimeout(() => {
        onClose();
      }, time);

      return () => clearTimeout(timer); // Clear timeout if modal is closed manually before time
    }
  }, [isOpen, time, onClose]);

  if (!isOpen) return null;

  const icon =
    type === 'Fail' ? (
      <FontAwesomeIcon icon={faCircleXmark} size="2x" color="red" />
    ) : (
      <FontAwesomeIcon icon={faCircleCheck} size="2x" color="green" />
    );
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 backdrop-blur-lg">
      <div className="bg-white rounded-lg shadow-xl p-8 h-auto">
        <div className="flex items-center justify-center mb-4">
          <span className="text-3xl">{icon}</span>
        </div>
        <p className="mt-4 text-center text-lg font-semibold text-gray-800">
          {message}
        </p>
      </div>
    </div>
  );
};

export default Modal;
