// src/Textarea.js
import React from 'react';

const Textarea = ({ value, onChange, placeholder,textAreaClassName}) => {
  return (
    <textarea
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      className={`${textAreaClassName}
      `}
    />
  );
};

export default Textarea;
