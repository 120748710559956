import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate, useLocation } from 'react-router-dom';
import MyExpenseList from './MyExpenseList';
import Loader from '../../components/modal/Loader';
import FileAttachedModal from '../../components/modal/FileAttachedModal';
import Button from '../../components/button/Button';
import ModifyRoutes from './ModifyRoutes';
import Modal from '../../components/modal/Modal';
import {
  fetchAllowanceType,
  fetchSundryAllowance,
  getAllowanceType,
  getSundryAllowance,
  getSelectedExpense,
  fetchDayWiseExpense,
  getDayWiseExpense,
  fetchAttachmentDayWise,
  getAttachmentDaywise,
  deletedAttachmentDayWise,
  fetchDayWiseTotalExpense,
  getDayWiseTotalExpense,
} from '../../redux/slice/myExpenseSlice';
import { getSelectedAttachment } from '../../redux/slice/unReportedSlice';

const MyExpenseDetails = () => {
  const location = useLocation();
  const selectedAttachment = location.state;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const allowanceType = useSelector(getAllowanceType);
  const sundryAllowanceType = useSelector(getSundryAllowance);
  const selectedMyExpense = useSelector(getSelectedExpense);
  const dairyWiseExpense = useSelector(getDayWiseExpense);
  const attachmentList = useSelector(getSelectedAttachment);
  const { attachmentDayWise, isLoading, apiError } =
    useSelector(getAttachmentDaywise);
  const { dayWiseTotalExpense } = useSelector(getDayWiseTotalExpense);

  console.log('dayWiseTotalExpense', dayWiseTotalExpense);
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedAttachmentList, setSelectedAttachmentList] = useState([]);
  const [showSuccessModal, setSuccessModal] = useState(false);

  const toggleDrawer = () => setDrawerOpen(!isDrawerOpen);
  const handleClick = () => alert('Button clicked!');

  useEffect(() => {
    dispatch(fetchAllowanceType());
    dispatch(fetchSundryAllowance());
    dispatch(fetchAttachmentDayWise());
    dispatch(fetchDayWiseTotalExpense());
  }, [dispatch]);

  useEffect(() => {
    if (selectedMyExpense) {
      const { activityDateFormat } = selectedMyExpense;
      localStorage.setItem('activityDateFormat', activityDateFormat);
      dispatch(fetchDayWiseExpense(activityDateFormat));
    } else {
      const activityDate = localStorage.getItem('activityDateFormat');
      if (activityDate) dispatch(fetchDayWiseExpense(activityDate));
    }
  }, [dispatch, selectedMyExpense]);

  useEffect(() => {
    if (selectedAttachment) {
      setSelectedAttachmentList((prevList) => {
        const isDuplicate = prevList.some(
          (item) => item.invoiceNumber === selectedAttachment.invoiceNumber
        );
        return isDuplicate ? prevList : [...prevList, selectedAttachment];
      });
    }
  }, [selectedAttachment]);

  const handleNavigate = () => {
    navigate('/my-expense');
    localStorage.removeItem('activityDateFormat');
    localStorage.removeItem('dayWiseData');
  };

  const handleDeleteAttachment = (expendId) => {
    dispatch(deletedAttachmentDayWise(expendId));
  };

  if (isLoading) {
    return <Loader />;
  }

  if (apiError) {
    return <div className="text-red-500">{apiError}</div>;
  }

  const handlerNavigate = (navigateTo) => {
    navigate(navigateTo);
  };

  const handlerSaveUpdate = () => {
    setSuccessModal(true);
  };
  const closeModal = () => {
    setSuccessModal(false);
  };

  const dividerClass = 'divider-y h-[2px] w-full bg-gray-300 mt-2';

  return (
    <div className="w-screen h-screen flex">
      <div className="w-full bg-white">
        <header className="flex items-center h-10">
          <button onClick={handleNavigate} className="pl-4">
            <FontAwesomeIcon
              icon="fa-solid fa-angle-left"
              size="lg"
              color="#A04D95"
            />
          </button>
          <h1 className="text-2xl font-bold text-primary pl-20">
            Expense Update
          </h1>
        </header>

        <div className="flex justify-end pr-4">
          <button
            onClick={toggleDrawer}
            className="font-bold"
            style={{ color:  '#2D5BFF'}}
          >
            Modify
          </button>
          <ModifyRoutes isOpen={isDrawerOpen} onClose={toggleDrawer} />
        </div>

        <div className="divider-y h-1 w-full bg-gray-300 mt-2"></div>

        {dairyWiseExpense &&
          dairyWiseExpense.length > 0 &&
          dairyWiseExpense.map((item, index) => (
            <div key={index}>
              <MyExpenseList id={index} item={item} />
              <div className="divider-y h-1 w-full bg-gray-300"></div>
            </div>
          ))}

        {/* <div className={dividerClass}></div> */}

        <div
          className="flex justify-between mt-2 p-4"
          style={{ backgroundColor: '#F7F0FF' }}
        >
          <div className="">
            <h3 className="font-bold">Total Expense Details</h3>
            <h6>Attach other allowance here</h6>
          </div>

          <button
            onClick={() => setShowModal(!showModal)}
            className="px-4 py-2 text-white rounded-3xl border-2"
            style={{ borderColor: '#A04D95' }}
          >
            <FileAttachedModal
              showModal={showModal}
              onClick={() => setShowModal(!showModal)}
            />
            <FontAwesomeIcon icon="fa-solid fa-paperclip" color="#A04D95" />
          </button>
        </div>

        <div className={dividerClass}></div>

        <h6 className="pr-4 pl-4">Other Allowance</h6>

        {attachmentDayWise &&
          attachmentDayWise.length > 0 &&
          attachmentDayWise.map((item) => (
            <div
              key={item.expenseAttachmentId}
              className="flex justify-between mt-2 pl-4 pr-4"
            >
              <p>{item.expenseType}</p>
              <p>{item.fare}</p>
            </div>
          ))}

        <div className={dividerClass}></div>

        <div className="flex justify-between pl-4 pr-4">
          <h1 className="text-xl font-bold">Total Expense</h1>
          <h1>{dayWiseTotalExpense}</h1>
        </div>

        <div className={dividerClass}></div>

        <div className="p-4">
          <h3 className="text-xl font-bold">Attachments</h3>
          <div className={dividerClass}></div>
          {attachmentDayWise &&
            attachmentDayWise.length > 0 &&
            attachmentDayWise.map((item) => (
              <div
                key={item.expenseAttachmentId}
                className="flex justify-between p-2 border border-gray-400 rounded-lg mt-2"
              >
                <div className="flex items-center">
                  <img
                    src={`data:image/png;base64,${item.imageBase64}`}
                    alt="attachment"
                    className="w-16 h-16 rounded-lg object-cover"
                  />
                  <div className="ml-2">
                    <p>{item.expenseType}</p>
                    <p>{item.fare}</p>
                  </div>
                </div>
                <button
                  onClick={() =>
                    handleDeleteAttachment(item.expenseAttachmentId)
                  }
                >
                  <FontAwesomeIcon
                    icon="fa fa-circle-xmark"
                    size="2x"
                    style={{ color: '#B5C0D0' }}
                  />
                </button>
              </div>
            ))}
        </div>

        {/* Footer Buttons */}
        <div className="flex items-center justify-between w-full gap-1  mt-2 bg-gray-200 p-4">
          <Button
            label="Back"
            btTextColor="text-primary"
            btBgColor="white"
            onClick={() => handlerNavigate('/my-expense')}
          />
          <Button
            label="Save"
            onClick={() =>handlerSaveUpdate()}
            btBgColor="bg-primary"
            btTextColor="text-white"
          />
        </div>
      </div>
      {showSuccessModal && (
        <Modal
          time={2000} //  2 seconds
          isOpen={showSuccessModal}
          message={"Expense Updated Successfully"}
          type={'success'}
          onClose={closeModal}
        />
      )}
    </div>
  );
};

export default MyExpenseDetails;
