import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faEdit, faCrop,faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { Link, useRouteError ,useNavigate} from 'react-router-dom';
import { currentImage } from '../../redux/slice/cameraSlice';
import { useSelector, useDispatch } from 'react-redux';

import Button from '../../components/button/Button';

const CapturedImage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isEditing, setIsEditing] = useState(false);
  const [invoiceName, setInvoiceName] = useState('');
  const receiptImage = useSelector((state) => state.camera.image);
  const cropImage = useSelector((state) => state.camera.cropImage);
  const fileImage = useSelector((state) => state.camera.file);
  const imageSrc = cropImage ? cropImage : fileImage ? fileImage : receiptImage;
  dispatch(currentImage(imageSrc));

  const handleEditClick = () => {
    setInvoiceName(invoiceName);
    setIsEditing(true);
  };

  const handleInputChange = (e) => {
    setInvoiceName(e.target.value);
  };

  const handleSaveClick = () => {
    setInvoiceName(invoiceName);
    setIsEditing(false);
  };

  const handleCancelClick = () => {
    setInvoiceName(invoiceName);
    setIsEditing(false);
  };

  const generateInvoiceNo = () => {
    setInvoiceName(Array.from({ length: 10 }, () =>
      Math.floor(Math.random() * 10)
    ).join(''));
  };

  useEffect(() => {
    generateInvoiceNo();
  }, []);


  const handlerNavigate = (navigateTo) => {
    navigate(navigateTo);
  };

  return (
    <div className="w-full h-full flex flex-col">
      {/* Header */}
      <div className="bg-white shadow-md flex items-center justify-between border-b border-gray-200 mb-6 p-4">
        {/* <Link to="/camera"> */}
          <button className="text-gray-500" onClick={() => handlerNavigate('/camera')}>
          <FontAwesomeIcon icon={faChevronLeft} className='text-primary size-5' />
        </button>
        {/* </Link> */}
        <div className="text-lg font-semibold flex-grow text-center">
          {isEditing ? (
            <div className="flex items-center">
              <input
                type="text"
                value={invoiceName}
                onChange={handleInputChange}
                className="border border-gray-300 rounded-lg p-1"
              />
              <button
                className="ml-2 bg-blue-500 text-white py-1 px-2 rounded-lg"
                onClick={handleSaveClick}
              >
                Save
              </button>
              <button
                className="ml-2 bg-gray-500 text-white py-1 px-2 rounded-lg"
                onClick={handleCancelClick}
              >
                Cancel
              </button>
            </div>
          ) : (
            <span className='text-primary font-bold'>{invoiceName}</span>
          )}
        </div>
        <div className="flex items-center space-x-2 gap-2">
          {!isEditing && (
            <button className="text-gray-500" onClick={handleEditClick}>
              <FontAwesomeIcon icon={faEdit} className='text-gray-500  size-5' />
            </button>
          )}
          <Link to="/crop-image">
            <button className="text-gray-500">
              <FontAwesomeIcon icon={faCrop} className='text-gray-500 size-5' />
            </button>
          </Link>
        </div>
      </div>

      {/* Image Display */}
      <div
        className="flex items-center justify-center p-4 "
        style={{ height: '70vh', width: '100vw' }}
      >
        <img
          src={imageSrc}
          // width={300}
          alt="Captured image"
          className="w-full h-full  bg-slate-400 rounded-lg"
        />
      </div>

      {/* Footer Buttons */}
      <div className="flex items-center justify-between w-full gap-1 p-4 mt-2">
          <Button
            label="Retake"
            btTextColor="text-primary"
            btBgColor="white"
            onClick={()=>handlerNavigate('/camera')}
          />
          <Button
            label="Process"
            onClick={()=>handlerNavigate('/captured-image-details')}
            btBgColor="bg-primary"
            btTextColor="text-white"
          />
        </div>
    </div>
  );
};

export default CapturedImage;
