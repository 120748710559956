

import axios from 'axios';
const baseUrl = 'https://keaexpenseapi.kea-sfe.com/api/ExpenseIdCreation'

export const getMyExpense = async () => {
    try {
        const response = await axios.get(`${baseUrl}/getMyExpense?employeeId=8275&month=03&year=2024&companyId=25`);
        console.log('Response: APIIIIIIIIII', response.data);
        return response.data;
    } catch (error) {
        console.error('Error fetching expense type:', error);
        return null;
    }
};


export const getExpenseActivity    = async () => {
    try {
        const response = await axios.get(`${baseUrl}/getExpenseActivity?employeeId=8275&companyId=25`);
        console.log('Response: APIIIIIIIIII', response.data);
        return response.data;
    } catch (error) {
        console.error('Error fetching expense type:', error);
        return null;
    }
}


export const getCityList = async () => {
    try {
        const response = await axios.get(`${baseUrl}/getExpenseCity?employeeId=9394`);
        console.log('Response: APIIIIIIIIII', response.data);
        return response.data;
    } catch (error) {
        console.error('Error fetching expense type:', error);
        return null;
    }
}
