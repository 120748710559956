import React, { useState } from "react";

const Counter = ({ value, onChange, min = -Infinity, max = Infinity }) => {
    const [counterState, setCounterState] = useState(0)
    const onChangehandler = (val) => {
        let newCount = value ?? counterState;
        newCount += val;
        if(newCount < min || newCount > max) {
            newCount -= val;
        }
        setCounterState(newCount);
        onChange && onChange({ action: val === 1 ? 'Increment' : 'Decrement', value: newCount });
    }

    return <div className='flex gap-3 border justify-between items-center border-gray w-20 px-2 rounded text-center bg-counter-bg-color my-1'>
        <button className="text-primary font-bold text-md grow" onClick={onChangehandler.bind(this, -1)}>-</button>
        <div className="text-primary">{value ?? counterState}</div>
        <button className="text-primary text-md grow font-bold"  onClick={onChangehandler.bind(this, 1)}>+</button>
    </div>
};

export default Counter;
