import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Select from '../../components/select';
import Counter from '../../components/Counter/Counter';
import Input from '../../components/input';
import Spinner from '../../components/Spinner';


const getTodayDate = (days = 0) => {
  const d = new Date();
  const date = d.getDate() + days
  const month = d.getMonth() + 1
  const year = d.getFullYear()
  return `${year}-${month}-${date > 9 ? date : '0' + date}`
}

const SfcFormOne = ({ formValues = {}, onChangeHandler, errors }) => {
  const { areaRange: employeeType, data: userInfo } = useSelector(
    (state) => state.sfcUserBasicInfo
  );
  const { isLoading, cities, territory, transport, categories } = useSelector(
    (state) => state.sfcFormFilters
  );

  useEffect(() => {
    if (employeeType && employeeType === 'T' && territory[0]?.territoryId) {
      onChangeHandler({
        target: { name: 'fromTerritoryId', value: territory[0].territoryId },
      });
      onChangeHandler({
        target: { name: 'toTerritoryId', value: territory[0].territoryId },
      });
    }
  }, [employeeType, territory, userInfo]);
  useEffect(() => {
    if(userInfo?.effectiveFromDate) {
      onChangeHandler({
        target: { name: 'effectiveDate', value: userInfo.effectiveFromDate },
      });
    }
  }, [userInfo])

  return (
    <div className="max-w-2xl mx-auto">
      {isLoading && <Spinner text="Loading" transparent />}
      <div className="flex justify-between px-2 items-center">
        <p className="text-secondary-400 grow text-fieldset pt-3">Journey</p>
        <Input
          className="p-[5px] m-0"
          type="date"
          varient="plane"
          disabled={!!userInfo?.effectiveFromDate}
          onChange={onChangeHandler}
          error={errors.effectiveDate}
          name="effectiveDate"
          value={formValues.effectiveDate}
          pattern='yyyy-mm-dd'
          min={userInfo?.lastEffectiveDate || getTodayDate(1)}
        />
      </div>
      <div className="w-full h-0.5 bg-gray-200 my-3"></div>
      <div className="">
        <Select
          options={territory}
          disabled={employeeType === 'T'}
          label={employeeType !== 'T' ? 'From Territory' : 'Territory'}
          labelAttri={'territoryName'}
          valueAttri={'territoryId'}
          onChange={onChangeHandler}
          value={formValues.fromTerritoryId}
          name="fromTerritoryId"
          error={errors.fromTerritoryId}
        />
        <Select
          disabled={!formValues.fromTerritoryId}
          options={cities}
          label={'From City'}
          labelAttri={'cityName'}
          valueAttri={'cityId'}
          onChange={onChangeHandler}
          value={formValues.fromCityId}
          name={'fromCityId'}
          error={errors.fromCityId}
        />
        {employeeType !== 'T' && (
          <Select
            options={territory}
            label={'To Territory'}
            labelAttri={'territoryName'}
            valueAttri={'territoryId'}
            onChange={onChangeHandler}
            value={formValues.toTerritoryId}
            name={'toTerritoryId'}
            error={errors.toTerritoryId}
          />
        )}

        <Select
          disabled={!formValues.toTerritoryId}
          options={cities}
          label={'To City'}
          labelAttri={'cityName'}
          valueAttri={'cityId'}
          onChange={onChangeHandler}
          value={formValues.toCityId}
          name={'toCityId'}
          error={errors.toCityId}
        />
        <div className="flex gap-1">
          <div className="w-1/2">
            <Select
              options={categories}
              label={'Category'}
              labelAttri={'categoryName'}
              valueAttri={'categoryId'}
              onChange={onChangeHandler}
              value={formValues.categoryId}
              name={'categoryId'}
              error={errors.categoryId}
            />
          </div>
          <div className="w-1/2">
            <Select
              options={transport}
              label={'Transport'}
              labelAttri={'transportName'}
              valueAttri={'transportId'}
              onChange={onChangeHandler}
              value={formValues.transportId}
              name={'transportId'}
              error={errors.transportId}
            />
          </div>
        </div>
        <div className="flex justify-between mt-5 p-5">
          <div>
            <label htmlFor="no of documents">No of Doctors</label>
            <Counter
              value={formValues.numberOfDoctors}
              min={0}
              onChange={(action) =>
                onChangeHandler({
                  target: { name: 'numberOfDoctors', value: action.value },
                })
              }
            />
          </div>
          <div>
            <label htmlFor="no of Chemist">No of Chemist</label>
            <Counter
              value={formValues.numberOfChemist}
              min={0}
              onChange={(action) =>
                onChangeHandler({
                  target: { name: 'numberOfChemist', value: action.value },
                })
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SfcFormOne;
