import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios, { all } from 'axios';

const baseURL = 'https://keaexpenseapi.kea-sfe.com/api/ExpenseIdCreation';
// const baseURL = "http://192.168.1.243:1105/api/ExpenseIdCreation";

export const getSfcCities = createAsyncThunk('getSfcCities', (id) => {
  return axios.get(`${baseURL}/SFCTerritoryCity`, {
    params: { territoryId: id },
  });
});

export const fetchSfcFormFilters = createAsyncThunk(
  'fetchSfcFormFilters',
  async (params) => {
    return Promise.all([
      axios.get(`${baseURL}/SFCTerritoryCategory`),
      axios.get(
        `${baseURL}/SFCTerritoryModeOfTransport?gradeId=1&type=expense`
      ),
      axios.get(`${baseURL}/SFCTerritory`, { params: params }),
    ]);
  }
);
// employeeId=9408&companyId=25
// sfcUserBasicInfo

export const sfcFormFilters = createSlice({
  name: 'sfcFormFilters',
  initialState: {
    isLoading: false,
    cities: [],
    territory: [],
    transport: [],
    categories: [],
    apiError: '',
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSfcFormFilters.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(fetchSfcFormFilters.fulfilled, (state, action) => {
      state.isLoading = false;
      state.categories = action.payload[0].data;
      state.transport = action.payload[1].data;
      state.territory = action.payload[2].data;
    });
    builder.addCase(fetchSfcFormFilters.rejected, (state) => {
      state.apiError = 'Something went wrong';
      state.isLoading = false;
      state.categories = [];
      state.transport = [];
      state.territory = [];
    });
    builder.addCase(getSfcCities.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(getSfcCities.fulfilled, (state, action) => {
      state.isLoading = false;
      state.cities = action.payload.data;
    });
    builder.addCase(getSfcCities.rejected, (state) => {
      state.apiError = 'Something went wrong';
      state.isLoading = false;
      state.cities = [];
    });
  },
});

export default sfcFormFilters.reducer;
