import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

const baseURL = 'https://keaexpenseapi.kea-sfe.com/api/ExpenseIdCreation';
// const baseURL = "http://192.168.1.243:1105/api/ExpenseIdCreation";

const defaultParams = {
  employeeId: 9408,
  sequenceNo: 1,
};
export const sfcTravelExpenceDelete = createAsyncThunk(
  'sfcTravelExpenceDelete',
  async (data) => {
    return axios.delete(`${baseURL}/SFCdelete`, {
      params: { ...data, ...defaultParams },
    });
  }
);

export const sfcDeleteExpence = createSlice({
  name: 'sfcDeleteExpence',
  initialState: {
    isLoading: false,
    data: null,
    apiError: '',
    isSuccess: false
  },
  reducers: {
    getInitialDeleteExpense: (state) => {
      state.isLoading = false,
      state.data =  null,
      state.apiError =  '',
      state.isSuccess =  false
    }
  },
  extraReducers: (builder) => {
    builder.addCase(sfcTravelExpenceDelete.pending, (state) => {
      state.isLoading = true;
      state.isSuccess = false
    });

    builder.addCase(sfcTravelExpenceDelete.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
      state.isSuccess = true
    });
    builder.addCase(sfcTravelExpenceDelete.rejected, (state) => {
      state.apiError = 'Something went wrong';
      state.isLoading = false;
      state.data = null;
      state.isSuccess = false
    });
  },
});
export const { getInitialDeleteExpense } = sfcDeleteExpence.actions
export default sfcDeleteExpence.reducer;
