import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlus } from '@fortawesome/free-solid-svg-icons';
import CardContainer from './CardContainer';
import Btn from './Btn';
import FormContainer from './FormContainer';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSfcTravelingExpences } from '../../redux/slice/sfcTravelingExpences';
import { fetchSfcFormFilters } from '../../redux/slice/sfcFormFilters';
import {
  clearState,
  updateSaveSuccess,
} from '../../redux/slice/sfcSaveTravelExpence';
import { getSfcTravelExpenseFormInitialState } from '../../redux/slice/sfcTravelExpenseForm';
import { postSfcSubmitTravelExpence } from '../../redux/slice/sfcCreationSubmit';
import SideBar from '../../components/sidebar/SideBar';
import Spinner from '../../components/Spinner';
import SuccessfullPopup from './SuccessfullPopup';
import { sfcUserDetails } from '../../redux/slice/sfcUserBasicInfo';
import { getInitialDeleteExpense } from '../../redux/slice/sfcTravelExpenseDelete';

const Sfc = () => {
  const [stepOne, setStepOne] = useState(false);
  const [detailedViewID, setDetailedViewId] = useState(null);
  const { isLoading: isFilterLoading, transport } = useSelector(
    (state) => state.sfcFormFilters
  );
  const {
    isSuccess: isSaveSuccess,
    data: sfcSaveTravelExpence = {},
  } = useSelector((state) => state.sfcSaveTravelExpence);
  const { data: sfcTravelingExpences, isLoading: isLoadingExpences } =
    useSelector((state) => state.sfcTravelingExpences);
  const { data: sfcTravelingExpenseDelete, isSuccess: isDeleteSuccess, isLoading: isDeleting } = useSelector(
    (state) => state.sfcDeleteExpence
  );
  const sfcFinalSubmit = useSelector((state) => state.sfcCreationSubmit);
  const { data: userDetails } = useSelector((state) => state.sfcUserBasicInfo);
  const [toggle, setToggle] = useState('');
  const dispatch = useDispatch();
  const { isEditingMode } = useSelector((state) => state.sfcTravelExpenseForm)

  const onClickHandler = (val) => {
    setDetailedViewId(null);
    if (toggle) {
      setToggle((prev) => (prev === val ? '' : val));
    } else {
      setToggle(val);
    }
  };

  useEffect(() => {
    if (userDetails) {
      const { employeeId, companyId } = userDetails;
      dispatch(fetchSfcFormFilters({ employeeId, companyId }));
    }
  }, [userDetails]);

  useEffect(() => {
    dispatch(fetchSfcTravelingExpences());
    dispatch(sfcUserDetails())
  }, [sfcTravelingExpenseDelete, sfcFinalSubmit?.data?.status]);

  return (
    <>
      {isSaveSuccess ? (
        <SuccessfullPopup
          onClose={() => dispatch(updateSaveSuccess(false))}
          displayText={isEditingMode ? 'Updated' : 'Added'}
        />
      ) : null}
      {isDeleteSuccess ? (
        <SuccessfullPopup
          onClose={() => dispatch(getInitialDeleteExpense())}
          displayText="Removed"
        />
      ) : null}
       {isLoadingExpences || isFilterLoading || isDeleting ? (
        <Spinner text="Loading" transparent />
      ) : null}
      {!stepOne && (
        <div className=" h-full w-lvw flex flex-col box-border">
          <div className="flex items-center justify-between px-3 py-5">
            <SideBar />
            <p className="text-primary font-semibold text-xl">
              {detailedViewID === null ? 'SFC' : 'SFC Detailed'}
            </p>
            <div className="m-2 px-2">
              <button
                onClick={() => {
                  setStepOne(true);
                  dispatch(clearState());
                  dispatch(getSfcTravelExpenseFormInitialState());
                }}
              >
                <FontAwesomeIcon
                  icon={faCirclePlus}
                  color={'#A04D95'}
                  size={'xl'}
                />
              </button>
            </div>
          </div>
          <div className="p-3 flex gap-6 items-center mb-2 max-w-full">
            <div className="grow overflow-scroll no-scroll">
              <div className="flex items-center gap-3">
                {transport.length
                  ? transport.map((val, index) => {
                      return (
                        <Btn
                          key={index}
                          text={val.transportName}
                          onClick={onClickHandler.bind(this, val.transportName)}
                          index={index}
                          isSelected={toggle === val.transportName}
                        />
                      );
                    })
                  : null}
              </div>
            </div>
          </div>
          <div className="grow overflow-scroll" style={{ rowGap: '2px' }}>
            <div className="flex bg-gray-300 flex-col gap-[1px]">
              {sfcTravelingExpences &&
                sfcTravelingExpences?.map((val, index) => {
                  return !toggle || val.transportName === toggle ? (
                    <CardContainer
                      display={detailedViewID === val.stpfcDetailid}
                      setDetailedViewId={setDetailedViewId}
                      onEdit={() => setStepOne(true)}
                      status={val?.status}
                      key={index}
                      setDisplayData={sfcSaveTravelExpence}
                      val={val}
                    />
                  ) : null;
                })}
            </div>
          </div>
          <div className="w-full flex justify-end items-center bg-white p-5 shadow-top">
            <button
              className="w-full bg-primary text-white rounded-full p-3"
              onClick={() => {
                const stpfcDetailIds =  sfcTravelingExpences
                .filter((expences) => expences.status === 'D')
                .map((expences) => expences.stpfcDetailid)

                if (userDetails && stpfcDetailIds.length) {
                  const {
                    employeeId,
                    companyId,
                    stpfcId,
                    effectiveFromDate: effectiveDate,
                  } = userDetails;
                  dispatch(
                    postSfcSubmitTravelExpence({
                      stpfcDetailIds,
                      employeeId,
                      companyId,
                      effectiveDate,
                      stpfcId,
                    })
                  );
                }
              }}
            >
              Submit
            </button>
          </div>
        </div>
      )}
      {stepOne && <FormContainer onBack={() => setStepOne(false)} />}
    </>
  );
};

export default Sfc;
