import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { baseURL, priodSatate } from '../../utils/Constant';
import { faVolumeHigh } from '@fortawesome/free-solid-svg-icons';

const initialState = {
  isLoading: false,
  modifyRoute: {
    priodSatate,
    activity: null,
    fromCity: null,
    toCity: null,
    stayOverNight: null,
    remark: null,
    routesTable: [],
  },
  myExpenseDashboard: [],
  // myExpenseData:[],
  myxpenseDetails: null,
  apiError: false,
  errorMessage: '',
  modifySubmit: [],
  allowanceType: [],
  sundryAllowance: [],
  selectedExpense: null,
  dayWiseExpense: [],
  expenseCityFilter: [],
  finalSubmitResponse: null,
};

// ------------------------------------------Testing with new Slice--------------------------------------------
//fetch data for dashboard   (Home Page)
export const fetchMyExpenseDashboard = createAsyncThunk(
  'myExpense/fetchMyExpenseDashboard',
  async () => {
    try {
      const response = await axios.get(
        `${baseURL}/ExpenseMainDashboard?EmployeeId=8275&CompanyId=25`
      );
      console.log(response.data.expenseMain);
      return response.data.expenseMain;
    } catch (error) {
      return error.message;
    }
  }
);

//create slice for my expense
export const myExpenseDashboardSlice = createSlice({
  name: 'myExpenseDashboard',
  initialState: {
    isLoading: false,
    myExpenseDashboardData: [],
    apiError: false,
    errorMessage: '',
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchMyExpenseDashboard.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchMyExpenseDashboard.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        console.log('action.payload', payload);
        state.myExpenseDashboardData = payload;
      })
      .addCase(fetchMyExpenseDashboard.rejected, (state, action) => {
        state.isLoading = false;
        state.errorMessage = action.error.message;
        state.apiError = true;
      });
  },
});

// my expense data
export const fetchMyExpenseData = createAsyncThunk(
  'myExpense/fetchMyExpenseData',
  async () => {
    try {
      let responseMovies = await axios.get(
        `${baseURL}/getMyExpense?employeeId=8275&month=03&year=2024&companyId=25`
      );
      console.log('responseMovies', responseMovies.data.getMyExpenseList);
      return responseMovies.data.getMyExpenseList;
    } catch (error) {
      return error.message;
    }
  }
);

export const myExpenseDataSlice = createSlice({
  name: 'myExpenseData',
  initialState: {
    isLoading: false,
    myExpenseData: [],
    apiError: false,
    errorMessage: '',
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchMyExpenseData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchMyExpenseData.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        console.log('action.payload', payload);
        state.myExpenseData = payload;
      })
      .addCase(fetchMyExpenseData.rejected, (state, action) => {
        state.isLoading = false;
        state.errorMessage = action.error.message;
        state.apiError = true;
      });
  },
});

// Total Expense Day wise
export const fetchDayWiseTotalExpense = createAsyncThunk('myExpense/fetchDayWiseTotalExpense',async () => {
    try {
      const { expenseDetailId, expenseId, activityDate } = JSON.parse(
        localStorage.getItem('dayWiseData')
      );
      if (expenseDetailId && expenseId && activityDate) {
        const response = await axios.get(
          `${baseURL}/expenseDayWiseTotal?employeeId=8275&expenseId=${expenseId}&expenseDetailId=${expenseDetailId}&activityDate=${activityDate}`
        );
        console.log('response.data', response.data.totalFare);
        return response.data.totalFare;
      }
    } catch (error) {
      return null;
    }
  }
);


const myDayWiseTotalExpenseSlice = createSlice({
  name: 'dayWiseTotalExpense',
  initialState: {
    isLoading: false,
    dayWiseTotalExpense: '',
    apiError: false,
    errorMessage: '',
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchDayWiseTotalExpense.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchDayWiseTotalExpense.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        console.log('action.payload', payload);
        state.dayWiseTotalExpense = payload;
      })
      .addCase(fetchDayWiseTotalExpense.rejected, (state, action) => {
        state.isLoading = false;
        state.errorMessage = action.error.message;
        state.apiError = true;
      });
  },
})

// .................................................................................................................................

// DOnt do any thing below

//My Expance Data
// export const fetchMyExpenseData = createAsyncThunk("myExpense/fetchMyExpenseData", async () => {
//     try {
//       let responseMovies = await axios.get(
//         `${baseURL}/getMyExpense?employeeId=8275&month=03&year=2024&companyId=25`
//       );
//       return responseMovies.data.getMyExpenseList;
//     } catch (error) {
//       return error.message
//     }
//   });

// modify route data submit  setExpenseModifySubmit
export const setExpenseModifySubmit = createAsyncThunk(
  'myExpense/setExpenseModifySubmit',
  async (data) => {
    try {
      const response = await axios.post(`${baseURL}/expenseModifySubmit`, data);
      return response?.data?.fileRecord;
    } catch (error) {
      return null;
    }
  }
);

export const fetchAllowanceType = createAsyncThunk(
  'myExpense/fetchAllowanceType',
  async (data) => {
    try {
      const response = await axios.get(`${baseURL}/ExpenseType`);
      return response.data;
    } catch (error) {
      return null;
    }
  }
);

export const fetchSundryAllowance = createAsyncThunk(
  'myExpense/fetchSundryAllowance',
  async (data) => {
    try {
      const response = await axios.get(`${baseURL}/expenseSundryAllowance`);
      return response.data.getMyExpenseList;
    } catch (error) {
      return null;
    }
  }
);

//DayWise Expense Update
export const fetchDayWiseExpense = createAsyncThunk(
  'myExpense/fetchDayWiseExpense',
  async (activityDate) => {
    try {
      const response = await axios.get(
        `${baseURL}/expenseUpdateDayWise?employeeId=8275&expenseId=5839&activityDate=${activityDate}`
      );
      console.log('response.data', response.data.getMyExpenseList[0]);
      const { expenseDetailId, expenseId } =
        response?.data?.getMyExpenseList[0];
      if (expenseDetailId && expenseId && activityDate) {
        localStorage.setItem(
          'dayWiseData',
          JSON.stringify({ expenseDetailId, expenseId, activityDate })
        );
      }
      return response?.data;
    } catch (error) {
      return null;
    }
  }
);

// get All the attachment for daywise expense
export const fetchAttachmentDayWise = createAsyncThunk(
  'unReported/fetchAttachmentDayWise',
  async () => {
    const { expenseDetailId, expenseId, activityDate } = JSON.parse(
      localStorage.getItem('dayWiseData')
    );
    console.log(
      'expenseDetailId,expenseId,activityDate',
      expenseDetailId,
      expenseId,
      activityDate
    );
    try {
      if (!expenseDetailId || !expenseId || !activityDate) {
        return null;
      } else {
        console.log(
          'expenseDetailId,expenseId,activityDate',
          expenseDetailId,
          expenseId,
          activityDate
        );
        const response = await axios.get(
          `${baseURL}/expenseGetAllAttachmentForDaywise?employeeId=8271&expenseId=${expenseId}&expenseDetailId=${expenseDetailId}&activityDate=${activityDate}`
        );
        console.log(
          'response.data',
          response.data.expenseGetAllAttachmentForDaywise
        );
        return response?.data?.expenseGetAllAttachmentForDaywise;
      }
    } catch (error) {
      return null;
    }
  }
);

// Delete Attachment
export const deletedAttachmentDayWise = createAsyncThunk(
  'myExpense/deletedAttachmentDayWise',
  async (attachmentId) => {
    try {
      console.log('attachmentId', attachmentId);
      const dayWiseData = JSON.parse(localStorage.getItem('dayWiseData'));
      const { expenseId, expenseDetailId, activityDate } = dayWiseData;
      console.log(
        'expenseId, expenseDetailId, activityDate',
        expenseId,
        expenseDetailId,
        activityDate
      );

      const response = await axios.delete(
        `${baseURL}/expenseDeleteAttachment`,
        {
          params: {
            employeeId: 8271,
            expenseId,
            expenseDetailId,
            activityDate,
            expenseAttachmentId: attachmentId,
          },
        }
      );

      console.log('Response data:', response.data);
      return response.data.expenseDeleteAttachmentList;
    } catch (error) {
      console.error('Error while deleting attachment:', error);
      return null;
    }
  }
);

export const attachmentDayWiseSlice = createSlice({
  name: 'attachmentDayWise',
  initialState: {
    attachmentDayWise: [],
    isLoading: false,
    apiError: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAttachmentDayWise.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchAttachmentDayWise.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        console.log('action.payload', payload);
        state.attachmentDayWise = payload;
      })
      .addCase(fetchAttachmentDayWise.rejected, (state, { payload }) => {
        state.isLoading = false;
        console.log('payload', payload);
        state.apiError = payload;
      })
      .addCase(deletedAttachmentDayWise.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deletedAttachmentDayWise.fulfilled, (state, { payload }) => {
        console.log('payload', payload);
        state.isLoading = false;
        state.attachmentDayWise = payload;
        state.apiError = false;
        // state.errorMessage = "";
      })
      .addCase(deletedAttachmentDayWise.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.apiError = payload;
      });
  },
});

// -------------------------------------------------------------

// ---------------------------fetchAttachmentDayWise--------------------------

//Fetch City Name for Filter in my expense
export const fetchExpenseCityFilter = createAsyncThunk(
  'myExpense/fetchExpenseCityFilter',
  async () => {
    try {
      const response = await axios.get(
        `${baseURL}/expenseCityFilter?employeeId=8275`
      );
      return response.data.expenseCityFilterList;
    } catch (error) {
      console.log('Error while  fetchExpenseCityFilter', error);
      return null;
    }
  }
);

// export

// ---------------------------------------------------

//Final my expense Submit
export const setExpenseFinalSubmit = createAsyncThunk(
  'myExpense/expenseFinalSubmit',
  async () => {
    try {
      const data = {
        employeeId: 8275,
        companyId: 25,
      };
      const response = await axios.post(`${baseURL}/expenseFinalSubmit`, data);
      return response.data;
    } catch (error) {
      console.log('Error while setExpenseFinalSubmit', error);
      return null;
    }
  }
);

//   slice create
const myExpenseSlice = createSlice({
  name: 'myExpense',
  initialState,
  reducers: {
    setSelectedExpense: (state, { payload }) => {
      state.selectedExpense = payload;
    },
  },
  extraReducers: (builder) => {
    //Dashboard Home page of My Expense Application
    // builder.addCase(fetchMyExpenseDashboard.pending, (state) => {
    //   state.isLoading = true;
    // });
    // builder.addCase(fetchMyExpenseDashboard.fulfilled, (state, { payload }) => {
    //   state.isLoading = false;
    //   state.myExpenseDashboard = payload;
    //   state.apiError = false;
    // });
    // builder.addCase(fetchMyExpenseDashboard.rejected, (state) => {
    //   state.isLoading = false;
    //   state.myExpenseDashboard = null;
    //   state.apiError = `Api Error while Dashborard `;
    // });

    //My Expance Data page
    // builder.addCase(fetchMyExpenseData.pending, (state) => {
    //   state.isLoading = true;
    // });

    // builder.addCase(fetchMyExpenseData.fulfilled, (state, { payload }) => {
    //   state.isLoading = false;
    //   state.myExpenseData = payload;
    //   state.apiError = false;
    // });

    // builder.addCase(fetchMyExpenseData.rejected, (state) => {
    //   state.isLoading = false;
    //   state.myExpenseData = null;
    //   state.apiError = "Something is Wrong With API!";
    // });

    //modify route sumbit
    builder.addCase(setExpenseModifySubmit.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(setExpenseModifySubmit.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.modifySubmit = payload;
      state.apiError = false;
    });
    builder.addCase(setExpenseModifySubmit.rejected, (state) => {
      state.isLoading = false;
      state.modifySubmit = null;
      state.apiError = 'Something is Wrong With API!';
    });

    // allowance type
    builder.addCase(fetchAllowanceType.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(fetchAllowanceType.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      console.log('payload', payload);
      state.allowanceType = payload;
      state.apiError = false;
    });
    builder.addCase(fetchAllowanceType.rejected, (state) => {
      state.isLoading = false;
      state.allowanceType = null;
      state.apiError = 'Something is Wrong With API!';
    });

    // sundry allowance
    builder.addCase(fetchSundryAllowance.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(fetchSundryAllowance.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.sundryAllowance = payload;
      state.apiError = false;
    });

    builder.addCase(fetchSundryAllowance.rejected, (state) => {
      state.isLoading = false;
      state.sundryAllowance = null;
      state.apiError = 'Something is Wrong With API!';
    });

    ///DayWise Expense Update
    builder.addCase(fetchDayWiseExpense.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(
      fetchDayWiseExpense.fulfilled,
      (state, { payload: { getMyExpenseList } }) => {
        state.isLoading = false;
        state.dayWiseExpense = getMyExpenseList;
        state.apiError = false;
      }
    );

    builder.addCase(fetchDayWiseExpense.rejected, (state) => {
      state.isLoading = false;
      state.dayWiseExpense = null;
      state.apiError = 'Something is Wrong With API!';
    });

    //city  Name for filter
    builder.addCase(fetchExpenseCityFilter.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(fetchExpenseCityFilter.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.expenseCityFilter = payload;
      state.apiError = false;
    });

    builder.addCase(fetchExpenseCityFilter.rejected, (state) => {
      state.isLoading = false;
      state.expenseCityFilter = null;
      state.apiError = 'Something is Wrong With API!';
    });

    //Final submit expense
    builder.addCase(setExpenseFinalSubmit.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(setExpenseFinalSubmit.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.finalSubmitResponse = payload?.fileRecord;
      state.apiError = false;
    });

    builder.addCase(setExpenseFinalSubmit.rejected, (state) => {
      state.isLoading = false;
      state.finalSubmitResponse = null;
      state.apiError = 'Something is Wrong With API!';
    });
  },
});

export const getMyExpenseDashboard = (state) => state.myExpenseDashboard;
export const getMyExpenseData = (state) => state.myExpenseData;
export const getModifySubmit = (state) => state.myExpense.modifySubmit;
export const getAllowanceType = (state) => state.myExpense.allowanceType;
export const getSundryAllowance = (state) => state.myExpense.sundryAllowance;
export const getSelectedExpense = (state) => state.myExpense.selectedExpense;
export const getDayWiseExpense = (state) => state.myExpense.dayWiseExpense;
export const getExpenseCityFilter = (state) =>
  state.myExpense.expenseCityFilter;
export const getFinalSubmitExpense = (state) =>
  state.myExpense.finalSubmitResponse;
export const { setSelectedExpense } = myExpenseSlice.actions;

// get my expense dashboard
// export const getMyExpenseDashboard

export const getAttachmentDaywise = (state) => state.attachmentDayWise;
export const getDayWiseTotalExpense = (state) => state.dayWiseTotalExpense;




//Reducer
export const myExpenseDataSliceReducer = myExpenseDataSlice.reducer;
export const myExpenseDashboardReducer = myExpenseDashboardSlice.reducer;
export const attachmentDayWiseReducer = attachmentDayWiseSlice.reducer;
export const dayWiseTotalExpenseReducer = myDayWiseTotalExpenseSlice.reducer;

export default myExpenseSlice.reducer;
