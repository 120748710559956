import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getExpenseType, expenseSubmit } from '../../api/cameraApi';
import { fetchUnReportedData } from '../../redux/slice/unReportedSlice';
import Dropdown from '../../components/dropdown/Dropdown';
import Button from '../../components/button/Button';
import { useNavigate } from 'react-router-dom';

const CapturedDetails = () => {
  // Function to generate random invoice number
  const generateInvoiceNo = () =>
    Array.from({ length: 10 }, () => Math.floor(Math.random() * 10)).join('');

  const [dropdownItems, setDropdownItems] = useState([]);
  const [isEdit, setEdit] = useState(false);
  const [invoiceDetails, setInvoiceDetails] = useState({
    todayDate: new Date().toISOString().split('T')[0],
    total: '1000',
    desc: '',
    invoiceNo: generateInvoiceNo(),
    expenseType: '',
  });

  const CurrentImage = useSelector((state) => state.camera.currentImage);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Fetch expense types on component mount
  useEffect(() => {
    const fetchExpenseTypes = async () => {
      try {
        const response = await getExpenseType();
        setDropdownItems(response);
      } catch (error) {
        console.error('Error fetching expense types:', error);
      }
    };
    fetchExpenseTypes();
  }, []);

  // Toggle Edit Mode
  const toggleEditInvoice = () => setEdit((prev) => !prev);

  // Handle input change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInvoiceDetails((prevDetails) => ({ ...prevDetails, [name]: value }));
  };

  // Handle dropdown change for expense type
  const handleExpenseTypeChange = (data) => {
    setInvoiceDetails((prevDetails) => ({
      ...prevDetails,
      expenseType: data.expense_Type,
    }));
  };

  // Handle form submission
  const handleSaveClick = async () => {
    const { todayDate: UploadDate, total: fare, desc, invoiceNo: InvoiceNumber, expenseType: ExpenseType } = invoiceDetails;

    const formData = {
      UploadDate,
      fare,
      desc,
      InvoiceNumber,
      ExpenseType,
      EmployeeId: 8271,
      CompanyId: 1,
      ImageBase64: CurrentImage,
      InvoiceDate: UploadDate,
    };

    try {
      const response = await expenseSubmit(formData);
      if (response) {
        dispatch(fetchUnReportedData());
        navigate('/un-reported-expenses');
      }
    } catch (error) {
      console.error('Error submitting expense:', error);
    }
  };

  // Navigation handler
  const handleNavigate = (path) => navigate(path);

  return (
    <div className="flex flex-col">
      {/* Image Preview */}
      <div className="w-full flex justify-center p-4">
        <img
          src={CurrentImage}
          alt="Captured"
          className="w-full max-w-xs h-auto rounded-lg"
          style={{ width: '13rem', height: '15rem' }}
        />
      </div>

      {/* Edit Button */}
      <div className="flex justify-end pl-4 pb-2 mt-4 pr-4">
        <button
          onClick={toggleEditInvoice}
          className="bg-gray-200 text-primary font-bold px-4 py-2 rounded"
        >
          {isEdit ? 'Save' : 'Edit'}
        </button>
      </div>

      {/* Expense Type Dropdown */}
      <div className="w-full flex justify-between mt-4 px-4">
        <p className="font-bold">Expense Type</p>
        <Dropdown
          label="Select Expense Type"
          name="expense_Type"
          value={invoiceDetails.expenseType}
          options={dropdownItems}
          attributeValue="expense_Type"
          attributeName="expense_Type"
          onChange={handleExpenseTypeChange}
        />
      </div>

      {/* Invoice Details */}
      <div className="w-full space-y-4 mt-4 px-4">
        <h3 className="text-lg font-bold">Invoice Details</h3>
        <div className="bg-gray-200 p-4 rounded-lg space-y-4">
          <div className="flex">
            <label className="w-1/3 text-gray-700 text-sm font-bold">Invoice Date</label>
            <input
              type="date"
              name="todayDate"
              value={invoiceDetails.todayDate}
              onChange={handleInputChange}
              className="w-2/3 py-2 bg-gray-200 text-end"
              readOnly={!isEdit}
            />
          </div>
          <div className="flex">
            <label className="w-1/3 text-gray-700 text-sm font-bold">Invoice Number</label>
            <input
              type="text"
              name="invoiceNo"
              value={invoiceDetails.invoiceNo}
              onChange={handleInputChange}
              className="w-2/3 py-2 bg-gray-200 text-end"
              readOnly={!isEdit}
            />
          </div>
          <div className="flex">
            <label className="w-1/3 text-gray-700 text-sm font-bold">Total</label>
            <input
              type="text"
              name="total"
              value={invoiceDetails.total}
              onChange={handleInputChange}
              className="w-2/3 py-2 bg-gray-200 text-end"
              readOnly={!isEdit}
            />
          </div>
        </div>
      </div>

      {/* Footer Buttons */}
      <div className="flex items-center justify-between w-full gap-1 p-4 mt-8">
        <Button
          label="Cancel"
          btTextColor="text-primary"
          btBgColor="white"
          onClick={() => handleNavigate('/camera')}
        />
        <Button
          label="Save"
          onClick={handleSaveClick}
          btBgColor="bg-primary"
          btTextColor="text-white"
        />
      </div>
    </div>
  );
};

export default CapturedDetails;
