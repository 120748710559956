import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Plane, ViceversaArrow } from './compoments/Icons';
import {
  faMotorcycle,
  faPenToSquare,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { sfcTravelExpenceDelete } from '../../redux/slice/sfcTravelExpenseDelete';
import { useDispatch } from 'react-redux';
import { upadateExpenseIdCreationFormDataForEdit } from '../../redux/slice/sfcTravelExpenseForm';
import CarIcon from '../../assets/icons/car.png';
import BusIcon from '../../assets/icons/bus.png';
import FlightIcon from '../../assets/icons/flight.png';

const getTransportTypeIcons = (id) => {
  switch (id) {
    case 1:
      return (
        <FontAwesomeIcon
          icon={faMotorcycle}
          className="text-primary"
          width={'20px'}
          height={'20px'}
        />
      );
    case 2:
    case 3:
      return (
        <img
          src={BusIcon}
          alt="bus-train-icon"
          width={'20px'}
          height={'20px'}
        />
      );
    case 4:
    case 6:
      return (
        <img src={CarIcon} alt="car-taxi-icon" width={'20px'} height={'20px'} />
      );
    case 5:
      return (
        <img
          src={FlightIcon}
          alt="flight-icon"
          width={'20px'}
          height={'20px'}
        />
      );
    default:
      return null;
  }
};

const CardContainer = ({ status, val, onEdit, display, setDetailedViewId }) => {
  const dispatch = useDispatch();

  return (
    <div
      className="p-4 bg-white overflow-hidden flex flex-col gap-2"
      onClick={() => {
        // setDisplay((prev) => !prev)
        display
          ? setDetailedViewId(null)
          : setDetailedViewId(val.stpfcDetailid);
      }}
    >
      <div className="flex flex-col gap-1">
        <div className="flex justify-between">
          <div className="flex gap-4 items-center">
            <div>{getTransportTypeIcons(val?.transportId)}</div>
            <div className="text-secondary">{val.transportName}</div>
            <div className="text-secondary">{val?.categoryName}</div>
          </div>
          <p
            className={`${
              status === 'A' || status === 'S'
                ? 'text-primary'
                : 'text-draft-bg-color'
            }  py-0.5 rounded font-medium`}
          >
            {status === 'A'
              ? 'Approved'
              : status === 'D'
              ? 'Draft'
              : status === 'S'
              ? 'Submitted'
              : null}
          </p>
        </div>
        <div className="flex justify-between">
          <div className="flex w-3/4 md:gap-40 my-lg:gap-7">
            <p className="w-1/2 text-ellipsis overflow-hidden">
              {val?.fromCityName}
            </p>
            <div>
              <ViceversaArrow />
            </div>
            <p className="w-1/2 text-ellipsis overflow-hidden">
              {val?.toCityName}
            </p>
          </div>
          <div className="text-left">
            <span className="text-primary">INR </span>
            {val?.total}
          </div>
        </div>
        {display ? (
          <div className="flex justify-between items-center text-secondary-200 text-sm">
            <p>Effective Date</p>
            <p>{val?.effectiveDate}</p>
          </div>
        ) : null}
      </div>
      <div className={`${display ? 'show' : 'hidden'} flex flex-col gap-3`}>
        <div className="flex justify-between">
          <div>Category</div>
          <div>{val?.categoryName}</div>
        </div>
        <div className="w-full h-[0.5px] bg-line-color "></div>
        <div className="flex justify-between text-gray-500">
          <div>One Way Distance</div>
          <div>{val?.oneWayDistance}</div>
        </div>
        <div className="flex justify-between text-gray-500">
          <div>Two Way Distance</div>
          <div>{val?.twoWayDistance}</div>
        </div>
        <div className="w-1/1 h-[0.5px] bg-line-color "></div>
        <div className="flex justify-between text-gray-500">
          <div>One way Fare</div>
          <div>{val?.oneWayFare}</div>
        </div>
        <div className="flex justify-between text-gray-500">
          <div>Two way Fare</div>
          <div>{val?.twoWayFare}</div>
        </div>
        <div className="flex justify-between text-gray-500">
          <div>Allowance</div>
          <div>{val?.allowance}</div>
        </div>
        <div className="flex justify-between bold">
          <div>Total</div>
          <div>{val?.total}</div>
        </div>
        <div className="w-1/1 h-[0.5px] bg-line-color mt-4 "></div>
      </div>
      <div className="flex justify-between ">
        {status === 'D' ? (
          <div>
            <button
              onClick={(event) => {
                event.stopPropagation();
                onEdit();
                dispatch(upadateExpenseIdCreationFormDataForEdit(val));
              }}
            >
              <FontAwesomeIcon
                icon={faPenToSquare}
                className="text-primary my-2"
              />
            </button>
          </div>
        ) : (
          <div></div>
        )}
        {status === 'D' ? (
          <div>
            <button
              onClick={(event) => {
                event.stopPropagation();
                dispatch(
                  sfcTravelExpenceDelete({
                    stpfcId: val?.stpfcId,
                    stpfcDetailid: val?.stpfcDetailid,
                  })
                );
              }}
            >
              <FontAwesomeIcon icon={faTrash} className="text-primary my-2" />
            </button>
          </div>
        ) : (
          <div></div>
        )}
      </div>
    </div>
  );
};

export default CardContainer;
