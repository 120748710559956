import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../components/modal/Loader';
import {
  getAllUnreportedExpense,
  fetchUnReportedData,
  setSelectedAttachment,
} from '../../redux/slice/unReportedSlice';
import { getUnReportedExpense } from '../../redux/slice/cameraSlice';
import SideBar from '../../components/sidebar/SideBar';

const UnReportedExpenses = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const expenseList = useSelector(getUnReportedExpense);
  const { unReportedData, isLoading, apiError } = useSelector(getAllUnreportedExpense);
  const [localUnReportedList, setLocalUnReportedList] = useState([]);

  useEffect(() => {
    if (!unReportedData || unReportedData.length === 0) {
      dispatch(fetchUnReportedData());
    } else {
      setLocalUnReportedList(unReportedData);
    }
  }, [unReportedData, dispatch]);

  const handlerAttachment = (invoice) => {
    if (state) {
      const getDayWiseData = JSON.parse(localStorage.getItem('dayWiseData'));
      const { activityDate, expenseDetailId, expenseId } = getDayWiseData;
      const { employeeId, expenseType, amount, description, unReportExpenseId } = invoice;

      const attachmentData = {
        activityDate,
        expenseDetailId,
        expenseId,
        employeeId,
        expenseType,
        fare: amount,
        description,
        unReportExpenseId,
        travelMode: 0,
      };
      dispatch(setSelectedAttachment(attachmentData));
      navigate('/my-expense-details');
    }
  };
  console.log('localUnReportedList', localUnReportedList);

  return (
    <div className="p-4 relative w-screen h-screen">
      {isLoading && <Loader />}
      {apiError && <h1 className='text-center text-red-500'>{apiError}</h1>}
      {!isLoading && !apiError && (
        <>
          <div className="flex justify-around items-center mb-4">
            <SideBar />
            <h1 className="text-2xl font-bold text-center text-primary">Un-Reported Expenses</h1>
            <Link to={'/'}>
              <FontAwesomeIcon icon="fa-solid fa-x" className="text-xl" color="gray" />
            </Link>
          </div>

          {localUnReportedList.length > 0 ? (
            localUnReportedList.map((invoice) => (
              <div key={invoice.unReportExpenseId} className="bg-gray-200 rounded-md flex justify-between p-4 mt-2" onClick={() => handlerAttachment(invoice)}>
                <img src={`data:image/jpg;base64,${invoice.imageBase64}`} alt="Unrecognized" className="w-24 h-24 object-cover rounded-md" />
                <div className="w-full p-4">
                  <div className="flex flex-row justify-between">
                    <p className="">{invoice.invoiceDate.slice(0, 10)}</p>
                    <p className="text-primary">{invoice.status || 'Unsubmitted'}</p>
                  </div>
                  <div className="flex flex-row justify-between">
                    <p className="font-semibold text-lg">{invoice.invoiceNumber || 'N/A'}</p>
                    <p className="font-semibold text-xl">INR {invoice.amount || 'N/A'}</p>
                  </div>
                  <div className="flex flex-row justify-between">
                    <p className="">{invoice.expenseType || 'N/A'}</p>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div>
              <p className="text-center">No Un-Reported Expense</p>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default UnReportedExpenses;
