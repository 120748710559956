import React from 'react';
const Setting = () => {
  return (
    <div className="flex items-center justify-center mt-12 flex-col">
      <p className="text-xl font-bold text-center">
        Welcome To Setting Page This page under development 😕
      </p>
    </div>
  );
};

export default Setting;
